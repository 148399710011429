import React, { useContext, useState } from 'react';


const Context = React.createContext(null);

export const APIControlProvider = ({children})=>{
    const [controller, setController] = useState(new AbortController());
    return(
        <Context.Provider value={{
            controller,
            setController,
        }}>
            {
                children
            }
        </Context.Provider>
    )
}

export const  useAPIControl = () => useContext(Context)