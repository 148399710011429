import { Link } from "react-router-dom"; // Assuming you're using React Router

const FormLink = ({ to, children }) => {
  return (
    <Link to={to} className="form-link text-gold hover:text-gold-dark">
      {children}
    </Link>
  );
};

export default FormLink;
