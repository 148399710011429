import React from "react";
import { styles } from "../../styles";

export default function Vision() {
  return (
    <section id="Vision">
      <div className={`w-full h-full ${styles.yPadding} ${styles.xPadding}`}>
        <h3 className="font-roboto font-bold text-heading text-4xl">Vision</h3>
        <div className="w-full h-full  relative overflow-hidden  py-5 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row items-center gap-5 pb-5  xl:pb-0 lg:gap-10">
            <img
              src={require("./../../assets/Rectangle135.png")}
              alt="Logo"
              className="w-full h-full lg:w-[495px] lg:h-[332px]"
            />
            <div className="flex items-center flex-col gap-3 lg:gap-5 justify-center">
              <p className=" font-normal font-poppins text-subheading text-base xl:text-lg">{`We believe that investing in real estate is one of the safest and most profitable ways to make your money grow. That's why we offer a one-of-a-kind investment solution that offers exceptional returns and unrivaled security.`}</p>
              <p className=" font-normal font-poppins text-subheading text-base xl:text-lg">{`Our innovative financial products allow you to invest in carefully selected real estate projects and benefit from an above-average return on investment. We are confident that we can offer you one of the highest returns on the market thanks to our rigorous approach and expertise in real estate investing.`}</p>
            </div>
          </div>
          <div className="w-full flex-col-reverse lg:flex-row  flex gap-5 lg:gap-10 items-center ">
            <div className="flex items-center flex-col  gap-3 lg:gap-3 justify-center">
              <p className=" font-normal font-poppins text-subheading text-base xl:text-lg">{`We don't just offer high returns. We are also concerned about the security of your investment. This is why we offer a unique guarantee, based on mortgage certificates. Mortgage notes are debt securities that are backed by tangible real estate, meaning your investment is protected by strong, secure assets.`}</p>
              <p className=" font-normal font-poppins text-subheading text-base xl:text-lg">{`At ELYTH Group, we take care of all real estate transactions for you. We select real estate projects with attractive return potential, and we take care of the management and implementation of each project. You don't need to worry about technical or administrative details, because we take care of everything.`}</p>
              <p className=" font-normal font-poppins text-subheading text-base xl:text-lg">{`Our team of real estate experts are here to guide you through every step of the investment process. We are here to answer all your questions and help you make informed real estate investment decisions.`}</p>
            </div>
            <img
              src={require("./../../assets/Rectangle136.png")}
              alt="Logo"
              className="w-full h-full lg:w-[495px] lg:h-[332px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
