import React from "react";
import { styles } from "../../styles";

export default function Solutions() {
  return (
    <section id="Solutions">
      <div className={`w-full h-full ${styles.yPadding} ${styles.xPadding}`}>
        <h3 className="font-roboto font-bold text-heading text-4xl">
          Solutions
        </h3>

        <div className="w-full">
          <div className=" flex items-center flex-col lg:flex-row  w-full gap-5  lg:gap-7 xl:gap-10  ">
            <div className="flex flex-col gap-3 lg:gap-4 xl:gap-5 pt-5">
              <h3 className="font-roboto font-bold text-heading text-2xl">
                HomeInvest
              </h3>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-lg">
                {`The HomeInvest investment solution consists of investing in real estate projects from their launch in order to build a yielding real estate portfolio. The investment is made in the form of a capital contribution, and the funds invested are used to finance the different stages of the real estate project, from design to marketing.`}
              </p>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-lg">
                {`Investors can thus benefit from a long-term rental return, once the real estate project is completed and rented out. The advantage of HomeInvest is that it allows investors to diversify their real estate portfolio, by investing in several real estate projects at once, with a minimum investment amount.`}
              </p>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-lg">
                {`In summary, HomeInvest offers a real estate investment opportunity by investing in real estate projects in the development phase, with attractive long-term return potential, while allowing diversification of the real estate portfolio.`}
              </p>
            </div>
            <img
              src={require("./../../assets/Rectangle143.png")}
              alt="Logo"
              className="w-full h-full lg:w-[420px] lg:h-[420px] object-cover xl:w-[551px] xl:h-[492px]"
            />
          </div>
          <div className=" flex items-center flex-col-reverse lg:flex-row   w-full gap-5 lg:gap-7 xl:gap-10   ">
            <img
              src={require("./../../assets/Rectangle144.png")}
              alt="Logo"
              className="w-full h-full lg:w-[420px] lg:h-[440px] object-cover xl:w-[551px] xl:h-[492px]"
            />
            <div className="flex flex-col  gap-3 lg:gap-4 xl:gap-5  pt-5 ">
              <h3 className="font-roboto font-bold text-heading text-2xl">
                PromInvest
              </h3>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-lg">
                {`The PromInvest investment solution consists of investing in a real estate development project from the outset, with the aim of generating capital gains. Unlike other types of real estate investments that are focused on generating rental income, PromInvest aims to maximize capital gains by investing in development-stage real estate projects.`}
              </p>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-lg">
                {`The principle of PromInvest is simple: investors provide funds to finance the different stages of the real estate project, from design to marketing. In exchange, they receive a stake in the real estate project, which can be sold once the project is completed and put on the market.`}
              </p>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-lg">
                {`The return on the investment is therefore determined by the capital gain realized on the sale of shares in the real estate project. PromInvest thus offers investors the opportunity to benefit from a potentially higher return than that of rental investments, with a medium or long term investment horizon.`}
              </p>
            </div>
          </div>
          <div className=" flex items-center flex-col-reverse   w-full gap-5 lg:gap-7 xl:gap-10 lg:pt-3  xl:pt-0 lg:flex-row-reverse   ">
            <img
              src={require("./../../assets/Rectangle145.png")}
              alt="Logo"
              className="w-full h-full lg:w-[420px] lg:h-[420px] object-cover xl:w-[551px] xl:h-[492px]"
            />
            <div>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-lg  pt-5 lg:pt-0">
                {`In summary, PromInvest is a real estate investment solution that allows investors to participate in real estate development projects from the start, with the objective of realizing capital gains on resale. This is an attractive option for those looking to diversify their real estate portfolio and maximize their earning potential, while getting involved in exciting real estate projects. With PromInvest, investors can benefit from exclusive access to quality real estate projects, carefully selected for their return potential. In addition, the PromInvest platform offers total transparency on the proposed real estate projects, with detailed information on the projects, deadlines and associated risks. Investors can therefore make informed decisions and invest with confidence in profitable real estate projects. Ultimately, PromInvest is an innovative and dynamic real estate investment solution, which offers investors the opportunity to actively participate in the growth and development of the real estate market, while maximizing their capital gains potential.`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
