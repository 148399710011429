import React, { useState } from "react";
import { styles } from "../../styles";

import { FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { IoLocation } from "react-icons/io5";
import { useProfileData, useUpdateProfile } from "../../service/master";
import { useAuthContext } from "../../context/Auth";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function MyAccount() {
  const { user } = useAuthContext();
  const userD = JSON.parse(user);
  const { data: profileData, isLoading,  refetch } = useProfileData(userD?.user?.id);
  const { mutateAsync } = useUpdateProfile(userD?.user?.id);
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    first_name: profileData?.first_name || '',
    last_name: profileData?.last_name || '',
    email: profileData?.username || '',
    phone: "+91" + profileData?.phone || '',
    address: profileData?.address ||  '',
  });
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");

  const [errors, setErrors] = useState({});


  useEffect(() => {
    if(profileData){
    setUserData(pv => ({
      ...pv,
      first_name: profileData?.first_name,
      last_name: profileData?.last_name,
      email: profileData?.username,
      phone: "+91" + profileData?.phone,
      address: profileData?.address,
    }))
    }
  },[profileData])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error on input change
  };

  const validateForm = () => {
    const newErrors = {};
    if (!userData.first_name) newErrors.first_name = "First name is required.";
    if (!userData.last_name) newErrors.last_name = "Last name is required.";
    if (!userData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!userData.phone) {
      newErrors.phone = "Phone number is required.";
    } 
    if (!userData.address) newErrors.address = "Address is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const res = await mutateAsync({
          username: userData?.email,
          address: userData?.address,
          first_name: userData?.first_name,
          last_name: userData?.last_name,
          country_code: code ? `+${code}` : profileData?.country_code,
          phone: phone || profileData?.phone
        });

        toast.success(res?.message);
        setIsEditing(false);
        refetch()
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <section id="MyAccount">
      <ToastContainer />
      <div className={`${styles.yPadding} ${styles.xPadding} w-full flex items-center justify-center flex-col h-full`}>
        <div className="flex w-full pb-5 h-full max-w-lg">
          <h3 className="font-roboto font-bold px-5 md:px-0 text-heading text-3xl">My Account</h3>
        </div>

        <div className="lg:max-w-lg p-5 md:p-10 flex flex-col border gap-3 border-border w-full h-full shadow-lg">
          {isLoading ?
            <p>Loading....</p>
           : 
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <FaUser size={25} />
                {isEditing ? (
                  <>
                    <input
                      type="text"
                      name="first_name"
                      value={userData.first_name}
                      onChange={handleInputChange}
                      className={`font-poppins font-medium text-base w-[140px] text-heading border-b border-gray-300 p-2 rounded focus:outline-none focus:border-primary ${errors.first_name ? 'border-red-500' : ''}`}
                      placeholder="First name"
                    />
                    <input
                      type="text"
                      name="last_name"
                      value={userData.last_name}
                      onChange={handleInputChange}
                      className={`font-poppins font-medium text-base w-[140px] text-heading border-b border-gray-300 p-2 rounded focus:outline-none focus:border-primary ${errors.last_name ? 'border-red-500' : ''}`}
                      placeholder="Last name"
                    />
                  </>
                ) : (
                  <>
                    <h2 className="font-roboto font-normal text-subheading">Name</h2>
                    <p className="font-poppins font-medium text-base text-heading">{userData.first_name} {userData.last_name}</p>
                  </>
                )}
              </div>
              <button
                type="button"
                className="text-primary font-semibold font-roboto"
                onClick={() => setIsEditing((prev) => !prev)}
              >
                {isEditing ? "Cancel" : "Edit"}
              </button>
            </div>
            {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
            {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}

            <div className="flex items-center gap-4">
              <MdEmail size={25} />
              {isEditing ? (
                <input
                  type="email"
                  name="email"
                  disabled
                  value={userData.email}
                  onChange={handleInputChange}
                  className={`font-poppins font-medium w-[300px] text-base text-heading border-b border-gray-300 p-2 rounded focus:outline-none focus:border-primary ${errors.email ? 'border-red-500' : ''}`}
                  placeholder="Enter your email"
                />
              ) : (
                <>
                  <h2 className="font-roboto font-normal text-subheading">Email</h2>
                  <p className="font-poppins font-medium text-base text-heading">{userData.email}</p>
                </>
              )}
            </div>
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

            <div className="flex items-center gap-4">
              <IoMdCall size={25} />
              {isEditing ? (
                <>
                    <PhoneInput
                      country={"eg"}
                      // enableSearch={true}
                      // def
                      inputClass={`font-poppins font-medium w-[300px] text-base text-heading border-b border-gray-200 py-2 rounded focus:outline-none focus:border-primary ${errors.phone ? 'border-red-500' : ''}`}
                      value={profileData?.country_code + profileData.phone}
                      onChange={(value, country, formattedValue) => {
                        // handleChange(value)
                        const numberStr = value.toString();
                        const codeStr = country?.dialCode?.toString();
                        const firstDigits = numberStr.substring(0, codeStr.length); // Get the first `codeStr.length` digits
                        const remainingNumber = numberStr.substring(codeStr.length); 
                        
                        if (firstDigits === codeStr) {
                            // console.log(remainingNumber);
                              setCode(country?.dialCode)
                              setPhone(remainingNumber)
                              // setFormValues({ ...formValues, mobile: remainingNumber });
                        }
                      }
                      }
                    />
                 {/* <input
                  type="tel"
                  name="phone"
                  value={userData.phone}
                  onChange={handleInputChange}
                  className={`font-poppins font-medium w-[300px] text-base text-heading border-b border-gray-300 p-2 rounded focus:outline-none focus:border-primary ${errors.phone ? 'border-red-500' : ''}`}
                  placeholder="Enter your phone number"
                /> */}
                </>
              ) : (
                <>
                  <h2 className="font-roboto font-normal text-subheading">Phone</h2>
                  <p className="font-poppins font-medium text-base text-heading">{profileData?.country_code}-{profileData.phone}</p>
                </>
              )}
            </div>
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}

            <div className="flex items-center gap-4">
              <IoLocation size={30} />
              {isEditing ? (
                <input
                  type="text"
                  name="address"
                  value={userData.address}
                  onChange={handleInputChange}
                  className={`font-poppins font-medium w-[300px] text-base text-heading border-b border-gray-300 p-2 rounded focus:outline-none focus:border-primary ${errors.address ? 'border-red-500' : ''}`}
                  placeholder="Enter your address"
                />
              ) : (
                <>
                  <h2 className="font-roboto font-normal text-subheading">Address</h2>
                  <p className="font-poppins font-medium text-base text-heading">{userData.address}</p>
                </>
              )}
            </div>
            {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}

            {isEditing && (
              <button type="submit" className="self-start mt-4 bg-primary text-white px-4 py-2 rounded">
                Submit
              </button>
            )}
          </form>
      }
        </div>
      </div>
    </section>
  );
}
