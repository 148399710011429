import { styles } from "../../styles";
import Button from "../../components/UI/Button";
import { IoLocationOutline } from "react-icons/io5";
import { Input } from "../../components/UI/Input";
import { Label } from "../../components/UI/label";
import { RadioGroup, RadioGroupItem } from "../../components/UI/radio-group";
import { Textarea } from "../../components/UI/textarea";
import Select from "react-dropdown-select";
import { useState } from "react";
import { useArea, useCountry, useInvestment, useRegion } from "../../service/master";
import { toast, ToastContainer } from "react-toastify";
import { useAddInvestment } from "../../service/auth";
import { useAuthContext } from "../../context/Auth";
import { useNavigate } from "react-router-dom";

export default function Investment() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const userData = JSON.parse(user);
  const { mutateAsync, isPending } = useAddInvestment();
  const { data: investmentAmount } = useInvestment();
  const [selectedOption, setSelectedOption] = useState(null);
  const [investmentChoice, setInvestmentChoice] = useState("");
  const [investmentThoughts, setInvestmentThoughts] = useState("");
  const [errors, setErrors] = useState({});

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedArea, setSelectedArea] = useState('');

  const { data: countryList } = useCountry();
  const { data: regionList } = useRegion();
  const { data: areaList } = useArea();

  const amountOptions = investmentAmount?.map((item) => ({
    amount: item?.amount,
    id: item?.id,
  }));

  const countryOptions = countryList?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const regionOptions = regionList?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const areaOptions = areaList?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  // Validation check
  const validateForm = () => {
    const newErrors = {};
    if (!selectedOption) newErrors.selectedOption = "Investment amount is required";
    if (!investmentChoice) newErrors.investmentChoice = "Investment choice is required";
    if (!investmentThoughts) newErrors.investmentThoughts = "Investment thoughts are required";
    if (!selectedCountry) newErrors.selectedCountry = "Country is required";
    if (!selectedRegion) newErrors.selectedRegion = "Region is required";
    if (!selectedArea) newErrors.selectedArea = "Area is required";
    return newErrors;
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      try {
        const res = await mutateAsync({
          user: userData?.user?.id,
          country: selectedCountry,
          region: selectedRegion,
          area: selectedArea,
          description: investmentThoughts,
          style_of_investment: investmentChoice === "homeinvest" ? "REALVEST" : "PROVEST",
          amount: parseInt(selectedOption?.amount),
        });

        toast.success(res?.message);
        setTimeout(() => {
          navigate("/");
        }, 1500);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
      setErrors({});
    }
  };

  // Clear error onBlur
  const handleBlur = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
  };

  return (
    <>
      <ToastContainer />
      <section id="ChangePassword">
        <div
          className={`${styles.yPadding} ${styles.xPadding} w-full flex items-center justify-center flex-col h-full`}
        >
          <div className="flex w-full pb-3 flex-col gap-3 h-full max-w-lg ">
            <h3 className="font-roboto font-bold text-heading text-3xl">
              Investment Form
            </h3>
          </div>

          <div className="lg:max-w-lg p-5 flex flex-col border gap-3 border-border w-full h-full shadow-lg">
            <form className="flex gap-4 flex-col" onSubmit={handleSubmit}>
              <Select
                className="bg-transparent font-poppins py-3 text-heading border-[#C8DCED] !placeholder:text-heading"
                placeholder="Select country"
                options={countryOptions}
                onChange={(option) => {
                  setSelectedCountry(option[0]?.value);
                  handleBlur('selectedCountry');
                }}
              />
              {errors.selectedCountry && <p className="text-red-500">{errors.selectedCountry}</p>}

              <Select
                className="bg-transparent font-poppins py-3 text-heading border-[#C8DCED] !placeholder:text-heading"
                placeholder="Select region"
                options={regionOptions}
                onChange={(option) => {
                  setSelectedRegion(option[0]?.value);
                  handleBlur('selectedRegion');
                }}
              />
              {errors.selectedRegion && <p className="text-red-500">{errors.selectedRegion}</p>}

              <Select
                className="bg-transparent font-poppins py-3 text-heading border-[#C8DCED] !placeholder:text-heading"
                placeholder="Select area"
                options={areaOptions}
                onChange={(option) => {
                  setSelectedArea(option[0]?.value);
                  handleBlur('selectedArea');
                }}
              />
              {errors.selectedArea && <p className="text-red-500">{errors.selectedArea}</p>}

              <Select
                className="bg-transparent font-poppins py-3 text-heading border-[#C8DCED] !placeholder:text-heading"
                placeholder="How much do you want to invest"
                options={amountOptions}
                labelField="amount"
                valueField="id"
                onChange={(option) => {
                  setSelectedOption(option[0]);
                  handleBlur('selectedOption');
                }}
              />
              {errors.selectedOption && <p className="text-red-500">{errors.selectedOption}</p>}

              <Label className="text-subheading font-poppins italic" htmlFor="terms">
                Where do you want to invest
              </Label>
              <RadioGroup
                className="flex items-center gap-5"
                defaultValue={investmentChoice}
                onValueChange={(value) => {
                  setInvestmentChoice(value);
                  handleBlur('investmentChoice');
                }}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="homeinvest" id="homeinvest" />
                  <Label htmlFor="homeinvest">Realvest</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="prominvest" id="prominvest" />
                  <Label htmlFor="prominvest">Provest</Label>
                </div>
              </RadioGroup>
              {errors.investmentChoice && <p className="text-red-500">{errors.investmentChoice}</p>}

              <Textarea
                className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                placeholder="When you think about investing in this"
                value={investmentThoughts}
                onChange={(e) => setInvestmentThoughts(e.target.value)}
                onBlur={() => handleBlur('investmentThoughts')}
              />
              {errors.investmentThoughts && <p className="text-red-500">{errors.investmentThoughts}</p>}

              <Button loading={isPending} className="mt-3 bg-primary" type="submit">
                Submit
              </Button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
