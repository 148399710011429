import React, { useState } from "react";
import FormContainer from "../../components/FormContainer";
import Button from "../../components/UI/Button";
import Logo from "../../components/Logo/Logo";
// import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../components/UI/InputOTP";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import OtpInput from 'react-otp-input';
import { useLogin, useOtpVerify, useVerifyUser } from "../../service/auth";

export default function VerifyEmail() {
  const navigate = useNavigate()
  const otpVerifyMutation = useOtpVerify()
  const verifyUserMutaiton = useVerifyUser()
  const location = useLocation();
  const state = location.state;
  const [otp, setOtp] = useState(''); // Initialize array to store OTP values


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      toast.error('Please enter a valid 6-digit OTP.');
      return;
    }

    if(state?.type === "userVerify"){
      handleUserVerify()
    }else {
      handleOTPVerification()
    }

  };

  const handleUserVerify = async () => {
    try {
      const res = await  verifyUserMutaiton.mutateAsync({
        "code": otp,
        "email": state.email
      })

      if(res?.status){
        toast.success(res?.message)
        setTimeout(() => {
            navigate('/auth/sign-in')
        },1500) 
       }

    } catch (error) {
      toast.error(error.response.data.message);
    }
  }



  const handleOTPVerification = async () => {
    try {
      const res = await  otpVerifyMutation.mutateAsync({
        "code": otp,
        "email": state.email
    })

    if(res?.status){
      setTimeout(() => {
          navigate('/auth/set-new-password', {state: {
            email: state.email,
            otp
          }})
      },1500) 
     }
    } catch (error) {
      console.log('error', error);
      toast.error(error.response.data.message);
    }
  }

  console.log('dsad', otpVerifyMutation?.isPending);

  return (
    <>
      <ToastContainer />
      <div className="flex w-full min-h-screen py-10 items-center flex-col justify-center flex-wrap md:flex-nowrap gap-4 bg-primary">
        <Logo />
        <FormContainer
          title="Verify Your Email"
          discription={`We have sent you a 6-digit code to ${state?.email}`}
        >
          <form className="flex gap-4 pt-5 flex-col" onSubmit={handleSubmit}>
            <div className="w-full flex justify-center items-center pt-3">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle={{
                  height: 50,
                  width: 50
                }}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <Button loading={otpVerifyMutation.isPending || verifyUserMutaiton.isPending} className="mt-5" type="submit">
              Verify Email
            </Button>
          </form>
        </FormContainer>
      </div>
    </>
  );
}
