import React, { useState } from "react";
import { Input } from "../../components/UI/Input";
import FormContainer from "../../components/FormContainer";
import Button from "../../components/UI/Button";
import Logo from "../../components/Logo/Logo";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetPassword } from "../../service/auth";

export default function SetNewPassword() {
  const navigate = useNavigate();
  const state = useLocation()?.state;
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const resetPwdMutation = useResetPassword();

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState);
  };

  const toggleVisibility2 = () => {
    setIsVisible2(prevState => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length === 0) {
      toast.error("Password should not be empty");
      return;
    }

    if (password.length < 8) {
      toast.error("Password must be at least 8 characters long.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      const res = await resetPwdMutation.mutateAsync({
        "email": state?.email,
        "code": state.otp,
        "new_password": password,
        "confirm_password": confirmPassword
      });

      if (res?.status) {
        toast.success(res?.message);
        setTimeout(() => {
          navigate('/auth/sign-in');
        }, 1500);
      }

    } catch (error) {
      navigate('/auth/forgot-password');
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex w-full min-h-screen py-10 items-center flex-col justify-center flex-wrap md:flex-nowrap gap-4 bg-primary">
        <Logo />
        <FormContainer
          title="Set New Password"
          description="Your new password must be different from the last password."
        >
          <form className="flex gap-4 pt-5 flex-col" onSubmit={handleSubmit}>
            <Input
              className="bg-background bg-opacity-[17%]"
              type={isVisible ? "text" : "password"}
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endContent={
                <button type="button" onClick={toggleVisibility}>
                  {isVisible ? <FaEye color="#fff" /> : <FaEyeSlash color="#fff" />}
                </button>
              }
            />
            <Input
              className="bg-background bg-opacity-[17%]"
              type={isVisible2 ? "text" : "password"}
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              endContent={
                <button type="button" onClick={toggleVisibility2}>
                  {isVisible2 ? <FaEye color="#fff" /> : <FaEyeSlash color="#fff" />}
                </button>
              }
            />

            <Button loading={resetPwdMutation.isPending} className="mt-5" type="submit">
              Save
            </Button>
          </form>
        </FormContainer>
      </div>
    </>
  );
}
