// import axios from "axios";
import axios from 'axios';
import {useState} from 'react';
// import {useNavigation} from '@react-navigation/native';
import {useQueryClient} from '@tanstack/react-query';
import Endpoints from '../api/index'
// import ToastMessage from '../../utils/ToastMessage';
// import LogsHelper from '../../utils/LogHelper';
import {useAPIControl} from '../context/APIControlContext/index'
import { APIQuery } from '../utils/QueryHelper';
import { useAuthContext } from '../context/Auth';

export class RequestParams {
  constructor({endpoint, method, data, token}) {
    this.endpoint = endpoint;
    this.method = method;
    this.data = data;
    this.token = token;
  }
}
export default function ({
  endpoint,
  method = 'GET',
  body = {},
  params,
  init = false,
  showMessage = false,
  promise = true,
  headers,
  showErrorMessage = true,
  onUploadProgress,
  token
}) {
  const queryClient = useQueryClient();
  // const selected_profile_id = useSelector(SelectedProfileId_);
  const [isLoading, setIsLoading] = useState(init);
  const [error, setError] = useState(null);
  const {logout} = useAuthContext()
  const [response, setResponse] = useState(null);
  const [signalController, setSignalController] = useState(
    new AbortController(),
  );

  const {controller, setController} = useAPIControl();
  // const Navigation = useNavigation();
  let userToken = null;

  const fetchPromise = ({body_ = {}, id, params_, isTokenRequired = true, Langauge}) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log('log token', token);
        if (isTokenRequired) {
          const userData = await localStorage.getItem('@auth');
          const data = JSON.parse(userData)
          userToken = token || data?.token;
        }
        
        console.log('ccc', userToken)
        
        setError(null);
        setIsLoading(true);
        let request = {
          url:
          Endpoints.baseUrl + endpoint +
          (id ? id : params || params_ ? APIQuery(params || params_) : ''),
          method: method,
        };
        if (isTokenRequired && userToken) {
          request['headers'] = {
            'Authorization': 'Bearer ' + userToken,
            // 'Authorization': 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI2ODAzMTgzLCJpYXQiOjE3MjY4MDMwNjMsImp0aSI6ImIwNTgwMjg0YzU0NDQ3MzY4OTVkYzFhNzA4ZGRmNjIxIiwidXNlcl9pZCI6IjUyNzMzOWY0LTkyOTktNGUyMi1iZGU4LTIwMzA4Yzg0MDhmNCJ9.-EsNi8FYxvt7MWe5yEBA8ugWPuclU075Vx9atKodbt4',
          };
        }
        if (headers) {
          request['headers'] = {
            ...request['headers'],
            ...headers,
          };
        }
        if (body_ instanceof FormData) {
          request['headers'] = {
            ...request['headers'],
            'Content-Type': 'multipart/form-data',
          };
        }
        if (method.toLowerCase?.() != 'get') {
          request.data = body;
        }
        if (Object.keys(body).length) {
          request.method = 'POST';
          request.data = body;
        }
        if (body_ && Object.keys(body_).length) {
          request.method = method != 'GET' ? method : 'POST';
          request.data = body_;
        }
        // LogsHelper("Request----", request)
        let response = await axios({
          ...request,
          signal: controller.signal,
          onUploadProgress,
        });
        if (showMessage && response?.data?.message) {
          console.log(response?.data?.message);
        }

        // LogsHelper('sad', response.data)
        setResponse(response.data);
        setIsLoading(false);
        resolve(response.data);
      } catch (e) {
        console.log('jaa', e?.response?.data)
        if (
          (e?.response?.data?.code === "token_not_valid" && 
            (e?.response?.data?.detail === "Given token not valid for any token type"))
        ) {
          controller.abort();
          setController(new AbortController());
          queryClient
            .cancelQueries()
            .then(data => {
              // LogsHelper('cc','dsadasd')
              logout();
            })
            .catch(err => {
              // handle the error if needed
            });
        }
        

        setIsLoading(false);
        setResponse(null);
        setError(e?.response?.data);
        console.log('token', JSON.stringify(token));
        console.log('requets____', JSON.stringify(Endpoints.baseUrl + endpoint));
        // console.log('error', JSON.stringify(e?.response?.data, null, 4));

        if (showErrorMessage) {
          if (e?.response?.data?.message?.errors) {
            console.log(e?.response?.data?.message?.errors[0].message);
          } else if (e?.response?.data?.msg || e?.response?.data?.message) {
            if (Array.isArray(e?.response?.data?.msg)) {
              console.log(e?.response?.data?.msg[0]);
            } else {
              // ToastMessage.Error(
              //   e?.response?.data?.msg || e?.response?.data?.message,
              // );
            }
          }
        }

        reject(e);
      }
    });
  };

  return {
    isLoading,
    error,
    response,
    signalController,
    fetchPromise,
  };
}