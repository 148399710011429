import React, { useCallback, useState, useEffect } from "react";
import { styles } from "../../styles";
import { RadioGroup, RadioGroupItem } from "../../components/UI/radio-group";
import { Label } from "../../components/UI/label";
import { Slider } from "../../components/UI/slider";
import { Separator } from "../../components/UI/separator";

export default function Calculator() {
  const [value, setValue] = useState('first');
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState('proinvest');

  const [low, setLow] = useState(100000);
  const [lowInvest, setLowInvest] = useState(100000); // Starting from 100'000 CHF
  
  const [lowestYear, setLowestYear] = useState(1);

  // const handleValueChange = useCallback((lowValue) => {
  //   setLow(100000 + lowValue * 50000);  // Start from 100'000 CHF
  // }, []);

  // const handleValueChanges = useCallback((lowValue) => {
  //   setLowInvest(100000 + lowValue * 50000);  // Start from 100'000 CHF
  // }, []);

  const handleValueChange = useCallback((lowValue) => {
    const minValue = 100000; // Minimum value is 100'000 CHF
    const maxValue = 5000000; // Maximum value is 5'000'000 CHF
    const range = maxValue - minValue; // The range between min and max
    
    // Calculate the new value based on the lowValue, ensuring it stays within the desired range
    const newValue = minValue + lowValue * (range / 100);
    setLow(newValue);
  }, []);
  
  const handleValueChanges = useCallback((lowValue) => {
    const minValue = 100000; // Minimum value is 100'000 CHF
    const maxValue = 5000000; // Maximum value is 5'000'000 CHF
    const range = maxValue - minValue; // The range between min and max
    
    // Calculate the new value based on the lowValue, ensuring it stays within the desired range
    const newValue = minValue + lowValue * (range / 100);
    setLowInvest(newValue);
  }, []);
  

  const handleYearChange = useCallback((lowValue) => {
    setLowestYear(1 + Math.floor(lowValue / 11));  // Start from 1 year
  }, []);
  
  const calc = () => {
    return numberWithCommas((35 * low * 0.01).toFixed(2));
  };
  const calcs = () => {
    return numberWithCommas(((35 * low * 0.01) + low).toFixed(2));
  };
  const homeCal = () => {
    return numberWithCommas((6 * lowInvest * lowestYear * 0.01).toFixed(2));
  };
  const annualcalc = () => {
    return numberWithCommas((6 * lowInvest * 0.01).toFixed(2));
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '’');
  };

  useEffect(() => {
    calc();
    homeCal();
  }, [low]);

  useEffect(() => {
    setTab('homeinvest');
  }, []);

  return (
    <>
      <section id="Calculator">
        <div
          className={`${styles.yPadding} ${styles.xPadding} w-full flex items-center justify-center flex-col h-full`}
        >
          <div className="flex  w-full pb-3  flex-col gap-3 h-full max-w-lg ">
            <h3 className="font-roboto font-bold  text-heading text-3xl">
              Calculator
            </h3>
          </div>

          <div className=" lg:max-w-lg p-5  flex flex-col border gap-3 border-border w-full h-full shadow-lg">
            <form className="flex gap-4  flex-col">
              {/* <div className="flex  w-full pb-3 items-center text-center  flex-col gap-1 h-full ">
                <h3 className="font-roboto font-bold text-heading text-xl">
                  Brokerage
                </h3>
                <p className="font-poppins  text-subheading text-sm font-normal">
                  My real estate project: €500,000
                </p>
              </div> */}

              <div className="space-y-2">
                <div className="flex justify-between items-center pb-2">
                  <h3 className="text-heading font-poppins " htmlFor="terms">
                    Personal contribution
                  </h3>
                  <h4>{tab === 'homeinvest' ? numberWithCommas(lowInvest) : numberWithCommas(low)} CHF</h4>
                </div>
                <Slider
                  onValueChange={(val) => {
                    if (tab === 'homeinvest') {
                      handleValueChanges(val);
                    } else {
                      handleValueChange(val);
                    }
                  }}
                />
                <div className="flex justify-between items-center pb-2">
                  <h4>100’000 CHF</h4>
                  <h4>5’000’000 CHF</h4>
                </div>
              </div>

              <Separator />
              <Label className="text-heading font-poppins " htmlFor="terms">
                Investment Type
              </Label>

              <RadioGroup
                className="flex items-center gap-5"
                defaultValue="homeinvest"
                onValueChange={(val) => {
                  setTab(val);
                }}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="homeinvest" id="homeinvest" />
                  <Label className="text-subheading" htmlFor="homeinvest">
                    Realvest
                  </Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="prominvest" id="prominvest" />
                  <Label className="text-subheading" htmlFor="prominvest">
                    Provest
                  </Label>
                </div>
              </RadioGroup>
              <Separator />

              {tab === "prominvest" && (
                <>
                  <div className="space-y-2">
                    <div className="flex justify-between items-center pb-2">
                      <h3 className="text-heading font-poppins " htmlFor="terms">
                        Duration
                      </h3>
                      <h4>2 Year</h4>
                    </div>
                    <Slider disabled defaultValue={[100]} max={100} step={1} />
                  </div>
                  <Separator />

                  <div className=" w-full items-center justify-center flex-col font-poppins flex gap-2">
                    <div className="flex items-center flex-col sm:flex-row text-center gap-1">
                      <h4 className="text-subheading font-normal">
                        Approximately <span className="text-heading font-semibold">35%</span> return of investment after 2 years.
                      </h4>
                    </div>
                    <div className="flex items-center flex-col sm:flex-row sm:gap-2">
                      <h4 className="text-subheading font-normal">
                        In 2 year you will receive 35% return of investment so CHF{' '}
                        {calc()} your inital amount {numberWithCommas(low)} CHF.
                        {' '} <span className="text-heading font-semibold">Total CHF {calcs()}</span>.
                      </h4>
                    </div>
                  </div>
                </>
              )}

              {tab === "homeinvest" && (
                <>
                  <div className="space-y-2">
                    <div className="flex justify-between items-center pb-2">
                      <h3 className="text-heading font-poppins " htmlFor="terms">
                        Duration
                      </h3>
                      <h4>{lowestYear} Year</h4>
                    </div>
                    <Slider onValueChange={val => handleYearChange(val)} max={100} step={1} />
                    <div className="flex justify-between items-center pb-2">
                      <h4>1 Year</h4>
                      <h4>10 Year</h4>
                    </div>
                  </div>
                  <div className=" w-full items-center justify-center flex-col font-poppins flex gap-2">
                    <div className="flex items-center flex-col sm:flex-row text-center gap-1">
                      <h4 className="text-subheading font-normal">
                        Approximately <span className="text-heading font-semibold">6%</span> return of investment per year.
                      </h4>
                    </div>
                    <Separator />
                    <span className="text-heading font-semibold">Annual Payment: {annualcalc()} CHF</span>
                    <Separator />
                    <div className="flex items-center flex-col sm:flex-row sm:gap-2">
                      <h4 className="text-subheading font-normal">
                        After {lowestYear} years, you will receive{' '}
                        <span className="text-heading font-semibold">{homeCal()}  CHF </span>
                        of interests plus your initial contribution amount.
                      </h4>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
