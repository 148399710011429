import { styles } from "../../styles";

export default function AccountDeletion() {
  return (
    <div
      className={`w-full h-[600px]   mx-auto ${styles.xPadding} ${styles.yPadding}`}
    >
      <h1 className="text-3xl font-bold mb-8">Request Account Deletion</h1>

      <div className="space-y-6 text-lg">
        <p>
          We understand that you wish to delete your Elyth account. To initiate
          the account deletion process, please send an email to{" "}
          <a
            href="mailto:contact@elythgroup.com"
            className="text-red-600 hover:underline"
          >
            contact@elythgroup.com
          </a>
        </p>
        <p>
          Our team will review your request and process it as soon as possible.
          Please note that account deletion is not an automated process and may
          take some time to complete. You will receive a confirmation email once
          your account has been successfully deleted.
        </p>
        <p>
          If you have any questions or concerns, please feel free to reach out
          to us at{" "}
          <a
            href="mailto:contact@elythgroup.com"
            className="text-red-600 hover:underline"
          >
            contact@elythgroup.com
          </a>
          .
        </p>
        <p>Thank you for your understanding.</p>{" "}
      </div>
    </div>
  );
}
