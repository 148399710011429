const styles = {
  xPadding: " xl:px-16 md:px-10 px-5",
  yPadding: "md:py-10 py-5",
  tPadding: "md:pt-10 pt-5",
  bPadding: "md:pb-10 pb-5",
  xMargin: " xl:px-16 lg:px-12 md:px-10 sm:px-8 px-5 ",
  yMargin: "md:my-10 my-5",
  xdivPadding: "px-4 lg:px-6  xl:px-10 ",
};

export { styles };
