import React, { useState } from "react";
import { styles } from "../../styles";
import FormContainer from "../../components/FormContainer";
import { Input } from "../../components/UI/Input";
import { IoLocationOutline } from "react-icons/io5";
import GoogleMapReact from "google-map-react";
import { Textarea } from "../../components/UI/textarea";
import Button from "../../components/UI/Button";
import { useContact } from "../../service/auth";
import { toast, ToastContainer } from "react-toastify";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function Contact() {
  const { mutateAsync, isPending } = useContact()
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    zipCode: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.zipCode) newErrors.zipCode = "Zip code is required";
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.message) newErrors.message = "Message is required";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      console.log("Form Submitted", formData);

      try {
        const res = await mutateAsync(
          {
            'first_name': formData.firstName,
            'last_name': formData.lastName,
            'email': formData.email,
            'phone': formData.phone,
            'address': formData.address,
            'city': formData.city,
            'zip_code': formData.zipCode,
            'subject': formData.subject,
            'message': formData.message,
          }
        )
        // console.log('dsad', res);
      toast.success(res?.message)
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          zipCode: "",
          subject: "",
          message: "",
        });
      } catch (error) {
        console.log('dasda',error?.response?.data);
        toast.error(error.response?.data?.phone[0])
        toast.error(error?.response?.data?.message)
      }


      // Here, you can send formData to your backend or handle as needed.
      // Reset form after submission if desired
      // setFormData({
      //   firstName: "",
      //   lastName: "",
      //   email: "",
      //   phone: "",
      //   address: "",
      //   city: "",
      //   zipCode: "",
      //   subject: "",
      //   message: "",
      // });
    }
  };

  return (
    <div>
      <ToastContainer />
      <section id="Contact">
        <div className={`${styles.yPadding} xl:px-16 md:px-10 px-0 w-full h-full`}>
          <h3 className="font-roboto font-bold px-5 md:px-0 text-heading text-4xl">
            Contact
          </h3>

          <div  className="grid lg:gap-20 grid-cols-1 lg:grid-cols-2 w-full h-full">
            <div className="px-5 md:px-0">
              <div className="flex flex-col gap-3 py-5">
                <h3 className="font-roboto font-bold text-heading text-xl">
                  ELYTH Group
                </h3>
                <p className="text-subheading font-poppins font-normal">
                  Baarerstrasse 135 CH-6300 Zug
                </p>
                <p className="text-gold font-poppins font-normal">
                  contact@elythgroup.com
                </p>
              </div>
              {/* <div className="w-full h-[50vh] lg:h-[73%] xl:h-[73%]">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "" }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
                </GoogleMapReact>
              </div> */}
            </div>
            {/* <div className="px-5 md:px-0"></div> */}
            <div>
              <FormContainer
                className="md:max-w-full !border-[#C8DCED] !border-opacity-[70%]"
                title=""
                discription=""
              >
                <form className="flex gap-4 flex-col" onSubmit={handleSubmit}>
                  <div className="grid grid-cols-2 gap-3 w-full">
                    <Input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      maxLength={20}
                      className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                      placeholder="Enter first name"
                    />
                    {errors.firstName && <span className="text-red-500">{errors.firstName}</span>}
                    <Input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                      placeholder="Enter last name"
                      maxLength={20}
                    />
                    {errors.lastName && <span className="text-red-500">{errors.lastName}</span>}
                  </div>
                  <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                    placeholder="Enter email address"
                    maxLength={90}
                  />
                  {errors.email && <span className="text-red-500">{errors.email}</span>}
                  <Input
                    type="number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                    placeholder="Enter phone number"
                    maxLength={13}
                  />
                  {errors.phone && <span className="text-red-500">{errors.phone}</span>}
                  <Input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Enter address"
                    className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                    endContent={<IoLocationOutline color="#000000" />}
                    maxLength={40}
                  />
                  {errors.address && <span className="text-red-500">{errors.address}</span>}
                  <div className="grid grid-cols-2 gap-3 w-full">
                    <Input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                      placeholder="Enter city"
                     maxLength={20}
                    />
                    {errors.city && <span className="text-red-500">{errors.city}</span>}
                    <Input
                      type="number"
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleChange}
                      className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                      placeholder="Enter zip code"
                      maxLength={6}
                    />
                    {errors.zipCode && <span className="text-red-500">{errors.zipCode}</span>}
                  </div>
                  <Input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                    placeholder="Enter subject"
                    maxLength={100}
                    endContent={<IoLocationOutline color="#000000" />}
                  />
                  {errors.subject && <span className="text-red-500">{errors.subject}</span>}
                  <Textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                    maxLength={500}
                    className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                  />
                  {errors.message && <span className="text-red-500">{errors.message}</span>}
                  <Button loading={isPending} className="mt-5 w-full Send rounded-none" type="submit">
                    Send
                  </Button>
                </form>
              </FormContainer>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
