import React from "react";
import { projectData } from "../../data/Index";
import { styles } from "../../styles";

export default function Hero() {
  return (
    <section id="Hero">
      <div className={`w-full h-full`}>
        <div
          className={`w-full h-full mt-5 bg-gold py-5 sm:py-10 ${styles.xPadding} mt-[-30px]`}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 w-full pt-[20px]">
            {projectData.map((item, index) => (
              <div
                className="flex gap-3 md:justify-center  border md:border-none py-4 px-5 md:px-0 md:py-0 items-center"
                key={index}
              >
                <img src={item.icon} alt="mySvgImage" />
                <div>
                  <h3 className="font-bold font-roboto text-white text-3xl">
                    {item.count}
                  </h3>
                  <h4 className="font-normal font-roboto text-white text-base md:text-sm lg:text-base">
                    {item.title}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={`w-full h-full  relative overflow-hidden    py-10 ${styles.xPadding}  `}
        >
          <div className="w-full flex  pb-5 md:pb-10 lg:pb-10  flex-col lg:flex-row items-center lg:items-start gap-5 md:gap-10">
            <img
              src={require("./../../assets/Rectangle140.png")}
              alt="Logo"
              className="w-full h-full  lg:w-[580px] lg:h-[302px]"
            />
            <div className="flex items-center flex-col gap-3 md:gap-5   justify-center">
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-xl">{`Find your next profitable real estate investment in a few clicks on our platform dedicated to rental investment and real estate development.`}</p>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-xl">{`Welcome to our premier real estate platform, where you can find quality rental and property development investments. Our platform is designed to make it easy to find and purchase real estate for investment purposes.`}</p>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-xl">{`We understand that real estate investing can be intimidating, especially if you are new to it. That's why we've created a user-friendly and intuitive platform that will allow you to quickly and easily find properties that meet your criteria.`}</p>
            </div>
          </div>
          <div className="w-full flex-col-reverse lg:flex-row  gap-5 pb-5 md:pb-10 lg:pb-10 flex md:gap-10 items-center lg:items-start ">
            <div className="flex items-center flex-col gap-3 md:gap-5 justify-center">
              <p className=" font-normal text-justify font-poppins text-subheading text-base xl:text-xl">{`We have a wide variety of properties on our platform, ranging from apartments and detached houses to large-scale real estate complexes. We work with renowned real estate developers and experienced investors to offer you the best opportunities.`}</p>
              <p className=" font-normal text-justify font-poppins text-subheading text-base xl:text-xl">{`Our platform also offers you a range of tools and resources to help you make informed decisions. We provide detailed information about each property, including photos, floor plans and key features. We also have calculation tools to help you assess the potential profitability of your investment.`}</p>
            </div>
            <img
              src={require("./../../assets/Rectangle141.png")}
              alt="Logo"
              className="w-full h-full lg:w-[580px] lg:h-[302px]"
            />
          </div>
          <div className="w-full flex-col lg:flex-row md:pb-10    gap-5 pb-5 flex items-center lg:items-start md:gap-10">
            <img
              src={require("./../../assets/Rectangle142.png")}
              alt="Logo"
              className="w-full h-full lg:w-[580px] lg:h-[302px] "
            />
            <div className="flex items-center  flex-col gap-3 md:gap-5 xl:gap-8 justify-center ">
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-xl   ">{`We understand that purchasing a property is a big commitment, which is why we provide exceptional customer service. Our team of real estate experts is on hand to answer all your questions and guide you every step of the way.`}</p>
              <p className=" font-normal font-poppins text-justify text-subheading text-base xl:text-xl  ">{`In short, if you are looking for a reliable real estate platform to find rental and real estate development investments, you are in the right place. We are committed to helping you achieve your real estate investment goals. Do not hesitate to browse our site to discover our offers and services.`}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
