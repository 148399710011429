import React from "react";
import { styles } from "../../styles";
import { Input } from "../../components/UI/Input";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Button from "../../components/UI/Button";
import { useChangePassword } from "../../service/auth";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useChangePassword();
  const [oldPasswordVisible, setOldPasswordVisible] = React.useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = React.useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState({});
  const [touched, setTouched] = React.useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const toggleOldPasswordVisibility = () => setOldPasswordVisible(!oldPasswordVisible);
  const toggleNewPasswordVisibility = () => setNewPasswordVisible(!newPasswordVisible);
  const toggleConfirmPasswordVisibility = () => setConfirmPasswordVisible(!confirmPasswordVisible);

  const validateFields = () => {
    const newError = {};
    if (!oldPassword) newError.oldPassword = "Old password is required.";
    if (!newPassword) newError.newPassword = "New password is required.";
    if (!confirmPassword) newError.confirmPassword = "Confirm password is required.";
    if (newPassword !== confirmPassword) {
      newError.confirmPassword = "New password and confirmation do not match.";
    }
    return newError;
  };

  const handleBlur = (field) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const newError = validateFields();
    setError(newError);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError({});

    // Mark all fields as touched to display validation errors
    setTouched({
      oldPassword: true,
      newPassword: true,
      confirmPassword: true,
    });

    const newError = validateFields();
    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }

    try {
      const res = await mutateAsync({
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
      toast.success(res?.message);
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <section id="ChangePassword">
        <div
          className={`${styles.yPadding} ${styles.xPadding} w-full flex items-center justify-center flex-col h-full`}
        >
          <div className="flex w-full pb-3 flex-col gap-3 h-full max-w-lg">
            <h3 className="font-roboto font-bold text-heading text-3xl">
              Change Password
            </h3>
            <p className="font-poppins text-subheading text-sm font-normal">
              Your new password must be different from the last password.
            </p>
          </div>

          <div className="lg:max-w-lg p-5 flex flex-col border gap-3 border-border w-full h-full shadow-lg">
            <form className="flex gap-4 flex-col" onSubmit={handleSubmit}>
              <Input
                className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                type={oldPasswordVisible ? "text" : "password"}
                placeholder="Enter old password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                onBlur={() => handleBlur("oldPassword")}
                endContent={
                  <button type="button" onClick={toggleOldPasswordVisibility}>
                    {oldPasswordVisible ? <FaEye color="grey" /> : <FaEyeSlash color="grey" />}
                  </button>
                }
              />
              {error.oldPassword && touched.oldPassword && (
                <p className="text-red-500">{error.oldPassword}</p>
              )}

              <Input
                className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                type={newPasswordVisible ? "text" : "password"}
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                onBlur={() => handleBlur("newPassword")}
                endContent={
                  <button type="button" onClick={toggleNewPasswordVisibility}>
                    {newPasswordVisible ? <FaEye color="grey" /> : <FaEyeSlash color="grey" />}
                  </button>
                }
              />
              {error.newPassword && touched.newPassword && (
                <p className="text-red-500">{error.newPassword}</p>
              )}

              <Input
                className="bg-transparent font-poppins text-heading border-[#C8DCED] placeholder:text-heading"
                type={confirmPasswordVisible ? "text" : "password"}
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={() => handleBlur("confirmPassword")}
                endContent={
                  <button type="button" onClick={toggleConfirmPasswordVisibility}>
                    {confirmPasswordVisible ? <FaEye color="grey" /> : <FaEyeSlash color="grey" />}
                  </button>
                }
              />
              {error.confirmPassword && touched.confirmPassword && (
                <p className="text-red-500">{error.confirmPassword}</p>
              )}

              <Button loading={isPending} className="mt-3 bg-primary" type="submit">
                Save
              </Button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
