import React from "react";
import { Group } from "../../assets/svg";
import { styles } from "../../styles";

const ourTeams = [
  {
    logo: Group,
    name: "NTHONY PUTIOT",
    title: "Director",
    email: "anthony.puthiot@elythgroup.com",
  },
  {
    logo: Group,
    name: "SAMUEL CHAPUIS",
    title: "Wealth management",
    email: "s.chapuis@elythgroup.com",
  },
  {
    logo: Group,
    name: "FRANÇOIS GIPPA",
    title: "Promotion manager",
    email: "f.gippa@elythgroup.com",
  },
  {
    logo: Group,
    name: "BERTAND ROSSIER",
    title: "Architect",
    email: "b.rossier@elythgroup.com",
  },
  {
    logo: Group,
    name: "NIKO KOSTADINOVIC",
    title: "Designer, 3D",
    email: "n.kostadinovic@elythgroup.com",
  },
  {
    logo: Group,
    name: "IONELIA PATOVA",
    title: "Executive assistant",
    email: "i.patova@elythgroup.com",
  },
];
export default function Team() {
  return (
    <>
      <section id="Vision">
        <div className={`w-full h-full ${styles.yPadding} ${styles.xPadding}`}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-10 gap-y-5  md:gap-y-7 lg:gap-x-12 xl:gap-x-20 lg:gap-y-10">
            {ourTeams.map((item, index) => (
              <div
                key={index}
                className="items-center flex-col justify-center  flex "
              >
                <div className="bg-[#E8E8E8] w-full h-full justify-center py-20 flex items-center">
                  <img src={item.logo} alt={item.title} />
                </div>
                <div className=" bg-primary w-full p-5 space-y-1 ">
                  <h3 className="font-roboto font-medium text-white text-lg">
                    {item.name}
                  </h3>
                  <h5 className="font-roboto font-normal text-white text-base">
                    {item.title}
                  </h5>
                  <p className="font-roboto font-normal text-[#81BFF2] text-base">
                    {item.email}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
