import React from "react";
import Header from "../components/Header"; // Assuming you have a Header component
import { Route, Routes } from "react-router-dom";
import routes from "../routes";
import TiltedCard from "../components/TiltedCard";
import Footer from "../components/Footer";
import { AuthProvider, useAuthContext } from "../context/Auth";
import { useEffect } from "react";

function HomeLayout() {
  return (
    <AuthProvider>
      <LoginMiddleWare />
      <div className="relative w-full h-full">
        {/* Navbar or header is here */}
        <div className="sticky top-0  w-full z-[999]">
          <Header />
        </div>

        <TiltedCard />
        <Routes>
          {/* Render routes that are meant for HomeLayout */}
          {routes
            .filter(({ layout }) => layout === "HomeLayout")
            .map(({ pages }) => {
              console.log("HomeLayout", pages);
              return pages.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ));
            })}
        </Routes>
        <Footer />
      </div>
    </AuthProvider>
  );
}

const LoginMiddleWare = () => {
  const {user, login} = useAuthContext()
  useEffect(() => {
   const data = localStorage.getItem('@auth')
   login(JSON.parse(data))
  },[])
  return null
}


export default HomeLayout;
