import React, { useState } from "react";
import { Input } from "../../components/UI/Input";
import FormContainer from "../../components/FormContainer";
import Button from "../../components/UI/Button";
import Logo from "../../components/Logo/Logo";
import { useForgotPassword } from "../../service/auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ForgotPass() {
  const navigate  = useNavigate()
  const forgotPassMutation = useForgotPassword();
  // State to capture email input
  const [email, setEmail] = useState("");

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      toast.error("Please enter your email address");
      return;
    } else if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    // If validation passes, trigger the forgot password logic
    try {
     const res =  await forgotPassMutation.mutateAsync({ email }); // Assuming the mutation accepts email
    //  console.log('ssad', res);
     if(res?.status){
      toast.success(res?.message);
      setTimeout(() => {
          navigate('/auth/verify-email', {state: {
            email
          }})
      },1500)
     }
    } catch (error) {
      // console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      {/* Toast container for showing notifications */}
      <ToastContainer />
      
      <div className="flex w-full min-h-screen py-10 items-center flex-col justify-center flex-wrap md:flex-nowrap gap-4 bg-primary">
        <Logo />
        <FormContainer
          title="Forgot Password"
          description="No worries, we’ll send you confirmation OTP"
        >
          {/* Form with submission handler */}
          <form className="flex gap-4 pt-5 flex-col" onSubmit={handleSubmit}>
            <Input
              type="email"
              className="bg-background bg-opacity-[17%]"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Capture input value
            />
            <Button loading={forgotPassMutation.isPending} className="mt-5" type="submit">
              Get OTP
            </Button>
          </form>
        </FormContainer>
      </div>
    </>
  );
}
