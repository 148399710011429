import { styles } from "../../styles";

export default function EULA() {
  return (
    <div
      className={`w-full h-full   mx-auto ${styles.xPadding} pb-5 md:pb-10 `}
    >
      <h1 className="text-3xl font-bold text-center mb-6">
        End-User License Agreement (EULA)
      </h1>

      <div className="space-y-6 text-sm">
        <p>
          This End-User License Agreement ("Agreement") is a legal agreement
          between you ("User" or "you") and Elyth ("Company," "we," or "us")
          governing your use of the Elyth mobile application, website, and
          related services (collectively, the "Software"). By downloading,
          installing, or using the Software, you agree to be bound by the terms
          of this Agreement. If you do not agree to these terms, do not install
          or use the Software.
        </p>

        <section>
          <h2 className="text-2xl font-semibold mb-2">1. License Grant</h2>
          <div className="space-y-2">
            <p>
              <strong>1.1 Limited License:</strong> The Company grants you a
              non-exclusive, non-transferable, revocable license to use the
              Software solely for your personal or internal business purposes,
              under the terms of this Agreement.
            </p>
            <p>
              <strong>1.2 Restrictions:</strong> You may not:
            </p>
            <ul className="list-disc list-inside pl-4">
              <li>
                Copy, modify, distribute, or create derivative works of the
                Software;
              </li>
              <li>
                Reverse engineer, decompile, or disassemble the Software, except
                to the extent permitted by law;
              </li>
              <li>
                Rent, lease, sublicense, or otherwise transfer rights to the
                Software;
              </li>
              <li>
                Use the Software for any unlawful purpose or in violation of
                applicable laws and regulations.
              </li>
            </ul>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">2. Ownership</h2>
          <p>
            The Software is owned and operated by the Company. All rights,
            title, and interest in and to the Software, including all
            intellectual property rights, are and shall remain the exclusive
            property of the Company or its licensors. This Agreement does not
            grant you any ownership rights to the Software.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            3. Updates and Modifications
          </h2>
          <div className="space-y-2">
            <p>
              <strong>3.1 Updates:</strong> The Company may provide updates,
              patches, or modifications to the Software to improve functionality
              or address security concerns. These updates may be automatically
              installed without prior notice.
            </p>
            <p>
              <strong>3.2 Modifications:</strong> The Company reserves the right
              to modify or discontinue the Software, or any part of it, at any
              time without prior notice or liability.
            </p>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            4. Privacy and Data Collection
          </h2>
          <p>
            Your use of the Software is subject to our Privacy Policy, which
            outlines how we collect, use, and protect your personal information.
            By using the Software, you consent to the data practices described
            in our Privacy Policy.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            5. Term and Termination
          </h2>
          <div className="space-y-2">
            <p>
              <strong>5.1 Term:</strong> This Agreement is effective until
              terminated by either party.
            </p>
            <p>
              <strong>5.2 Termination by User:</strong> You may terminate this
              Agreement by uninstalling the Software and ceasing all use.
            </p>
            <p>
              <strong>5.3 Termination by Company:</strong> The Company may
              terminate this Agreement at any time if you breach any provision
              of this Agreement or engage in prohibited conduct.
            </p>
            <p>
              <strong>5.4 Effect of Termination:</strong> Upon termination, you
              must cease all use of the Software and delete any copies in your
              possession or control.
            </p>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            6. Disclaimer of Warranties
          </h2>
          <p>
            The Software is provided "AS IS" and "AS AVAILABLE," without
            warranty of any kind, express or implied. The Company disclaims all
            warranties, including but not limited to merchantability, fitness
            for a particular purpose, and non-infringement. The Company does not
            guarantee that the Software will be error-free, secure, or available
            at all times.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            7. Limitation of Liability
          </h2>
          <p>
            To the maximum extent permitted by law, the Company shall not be
            liable for any indirect, incidental, special, consequential, or
            punitive damages, including loss of profits or data, arising out of
            or related to your use or inability to use the Software. In no event
            shall the Company's total liability exceed the amount you paid, if
            any, for accessing the Software.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">8. Governing Law</h2>
          <p>
            This Agreement shall be governed by and construed in accordance with
            the laws of [Insert Jurisdiction], without regard to its conflict of
            law principles. Any disputes arising under this Agreement shall be
            resolved in the courts located in [Insert Jurisdiction].
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">9. Miscellaneous</h2>
          <div className="space-y-2">
            <p>
              <strong>9.1 Severability:</strong> If any provision of this
              Agreement is found to be unenforceable, the remaining provisions
              shall remain in full force and effect.
            </p>
            <p>
              <strong>9.2 Entire Agreement:</strong> This Agreement constitutes
              the entire agreement between you and the Company regarding your
              use of the Software, superseding any prior agreements or
              understandings.
            </p>
            <p>
              <strong>9.3 No Waiver:</strong> The failure of the Company to
              enforce any provision of this Agreement shall not be deemed a
              waiver of its rights.
            </p>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            10. Contact Information
          </h2>
          <p>
            If you have any questions about this Agreement, please contact us:
          </p>
          <p className="mt-2">
            <strong>Email:</strong>{" "}
            <a
              href="mailto:contact@elythgroup.com"
              className="text-primary hover:underline"
            >
              contact@elythgroup.com
            </a>
          </p>
          <p>
            <strong>Address:</strong>
            <br />
            Elyth Group
            <br />
            Route de Porrentruy 5<br />
            2942 Alle
            <br />
            Switzerland
          </p>
        </section>

        <p className="mt-6 font-semibold">
          By installing or using the Software, you acknowledge that you have
          read, understood, and agree to be bound by this Agreement.
        </p>
      </div>
    </div>
  );
}
