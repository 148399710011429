import { styles } from "../../styles";

export default function PrivacyPolicy() {
  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <div
      className={`w-full h-full pb-5 md:pb-10   mx-auto ${styles.xPadding}  `}
    >
      <h1 className="text-3xl font-bold text-center mb-2">Privacy Policy</h1>
      <p className="text-center text-gray-600 mb-6">
        Effective Date: {currentDate}
      </p>

      <div className="space-y-6">
        <section>
          <p>
            At Elyth, we are committed to protecting your privacy and ensuring
            that your personal information is handled securely and responsibly.
            This Privacy Policy outlines how we collect, use, and protect your
            data when you use our platform and services. By accessing or using
            Elyth, you agree to the terms of this Privacy Policy.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            1. Information We Collect
          </h2>
          <p>We collect the following types of information:</p>
          <h3 className="text-xl font-semibold mt-4 mb-2">
            1.1 Personal Information:
          </h3>
          <ul className="list-disc list-inside pl-4">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            2. How We Use Your Information
          </h2>
          <p>We use your information to:</p>
          <ul className="list-disc list-inside pl-4">
            <li>Provide, operate, and improve our platform</li>
            <li>Personalize your experience</li>
            <li>Communicate with you about updates, offers, and support</li>
            <li>Process payments and transactions</li>
            <li>Analyze usage patterns to enhance our services</li>
            <li>Comply with legal obligations</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            3. Sharing Your Information
          </h2>
          <p>
            We do not sell or rent your personal information to third parties.
            However, we may share your information in the following
            circumstances:
          </p>
          <div className="space-y-4 mt-4">
            <div>
              <h3 className="text-xl font-semibold mb-2">
                3.1 Service Providers:
              </h3>
              <p>
                We work with trusted third-party service providers to perform
                tasks on our behalf, such as payment processing, analytics, and
                customer support.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">
                3.2 Legal Compliance:
              </h3>
              <p>
                We may disclose your information if required to do so by law or
                if we believe such action is necessary to comply with legal
                obligations, protect our rights, or address security concerns.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">
                3.3 Business Transfers:
              </h3>
              <p>
                In the event of a merger, acquisition, or sale of assets, your
                information may be transferred to the new owner.
              </p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">4. Data Security</h2>
          <p>
            We implement robust security measures to protect your information
            from unauthorized access, loss, or misuse. However, no method of
            data transmission or storage is 100% secure. We encourage you to
            take precautions when sharing sensitive information online.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">5. Your Rights</h2>
          <p>You have the right to:</p>
          <ul className="list-disc list-inside pl-4">
            <li>Access, update, or delete your personal information</li>
            <li>Opt-out of marketing communications</li>
            <li>Restrict or object to certain data processing activities</li>
          </ul>
          <p className="mt-4">
            To exercise these rights, please contact us at{" "}
            <a
              href="mailto:contact@elythgroup.com"
              className="text-primary hover:underline"
            >
              contact@elythgroup.com
            </a>
            .
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">6. Third-Party Links</h2>
          <p>
            Our platform may contain links to third-party websites or services.
            We are not responsible for the privacy practices or content of these
            third-party entities. Please review their privacy policies before
            providing any personal information.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">7. Children's Privacy</h2>
          <p>
            Elyth is not intended for individuals under the age of 18. We do not
            knowingly collect personal information from children. If we become
            aware of such data, we will take steps to delete it promptly.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">8. Policy Changes</h2>
          <p>
            We may update this Privacy Policy periodically to reflect changes in
            our practices or legal requirements. We will notify you of
            significant updates by posting the revised policy on our platform
            and updating the "Effective Date" at the top.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">9. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us:
          </p>
          <p className="mt-2">
            <strong>Email:</strong>{" "}
            <a
              href="mailto:contact@elythgroup.com"
              className="text-primary hover:underline"
            >
              contact@elythgroup.com
            </a>
          </p>
          <p>
            <strong>Address:</strong>
            <br />
            Elyth Group
            <br />
            Route de Porrentruy 5<br />
            2942 Alle
            <br />
            Switzerland
          </p>
        </section>

        <section className="mt-8">
          <p className="text-center font-semibold">
            Thank you for trusting Elyth with your real estate investment needs.
          </p>
        </section>
      </div>
    </div>
  );
}
