import React from "react";
import { twMerge } from "tailwind-merge";

const FormContainer = ({ children, title, discription, className }) => {
  return (
    <div
      className={twMerge(
        "md:max-w-lg w-full   border-background border-opacity-[17%] border-y-2 md:border-2 mt-5 py-8 px-8",
        className
      )}
    >
      <div className="w-full flex items-center gap-1  flex-col  ">
        <h2 className="form-title font-roboto text-white font-bold text-3xl">
          {title}
        </h2>
        <p className="form-title font-poppins text-center text-white font-normal text-base">
          {discription}
        </p>
      </div>
      {children}
    </div>
  );
};

export default FormContainer;