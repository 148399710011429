import React from "react";
import { Separator } from "./UI/separator";
import { styles } from "../styles";
import { Facebook, Instagram, Twitter } from "../assets/svg";
import FormLink from "./FormLink";
import { Link } from "react-router-dom";

const FooterData = [
  { icon: Facebook, herf: "/", title: "Home", url: "https://www.google.com/" },
  {
    icon: Twitter,
    herf: "/vision",
    title: "Vision",
    url: "https://www.google.com/",
  },
  {
    icon: Instagram,
    herf: "/projects",
    title: "Projects",
    url: "https://www.google.com/",
  },
];
const PageData = [
  {
    herf: "/privacy-policy",
    title: "Privacy Policy",
  },
  {
    herf: "/account-deletion",
    title: "Account Deletion",
  },
  {
    herf: "/eula",
    title: "EULA",
  },
];

export default function Footer() {
  return (
    <>
      <footer
        className={` bg-primary  grid-cols-5 py-4 hidden md:grid   ${styles.xPadding}`}
      >
        <div className="col-span-2 mr-5 flex justify-center gap-2 flex-col  h-24">
          <div className="w-full flex gap-4  ">
            {FooterData.map((item, index) => (
              <div key={index}>
                <FormLink to={item.herf}>{item.title}</FormLink>
              </div>
            ))}
          </div>

          <div className="bg-[#2F698D] w-full h-[2px]"></div>
          <div className=" w-full flex justify-start items-center">
            <p className="font-roboto font-normal text-xs lg:text-base  text-subheadingLite">
              Contact: contact@elythgroup.com
            </p>
          </div>
        </div>
        <div className="col-span-1 w-full flex justify-center items-center">
          <img
            src={require("./../assets/elythWhte.png")}
            alt="Logo"
            className=" w-full object-contain  h-10  md:h-14 lg:h-[80px]"
          />
        </div>
        <div className="col-span-2  ml-5  flex justify-center gap-2 flex-col  h-24">
          <div className="w-full flex gap-4  justify-between ">
            <div className="flex gap-3 items-center">
              {PageData.map((item, index) => (
                <div key={index}>
                  {/* <Link to={item.url} target="_blank"> */}
                  <div key={index}>
                    <FormLink to={item.herf}>{item.title}</FormLink>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex gap-3 items-center">
              {FooterData.map((item, index) => (
                <div key={index}>
                  {/* <Link to={item.url} target="_blank"> */}
                  <Link>
                    <img src={item.icon} alt="social link" />
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-[#2F698D] w-full h-[2px]"></div>
          <div className=" w-full flex justify-end items-center">
            <p className="font-roboto font-normal text-xs lg:text-base text-end text-subheadingLite">
              © 2024 ELYTH Group. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
      {/* // for Mobile */}
      <footer>
        <Separator className="bg-[#2F698D] " />
        <div className={` bg-primary py-5 md:hidden  ${styles.xPadding}`}>
          <img
            src={require("./../assets/elythWhte.png")}
            alt="Logo"
            className=" w-fit object-contain  h-14  "
          />

          <div className="flex gap-3 flex-col w-full py-5">
            <div className="flex justify-between items-center w-full">
              <div className=" flex gap-3   items-center">
                {FooterData.map((item, index) => (
                  <div key={index}>
                    <FormLink to={item.herf}>{item.title}</FormLink>
                  </div>
                ))}
              </div>
              <div className=" flex gap-3  items-center">
                {FooterData.map((item, index) => (
                  <div key={index}>
                    {/* <Link to={item.url} target="_blank"> */}
                    <Link>
                      <img src={item.icon} alt="social link" />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex gap-3 items-center">
              {PageData.map((item, index) => (
                <div key={index}>
                  {/* <Link to={item.url} target="_blank"> */}
                  <div key={index}>
                    <FormLink to={item.herf}>{item.title}</FormLink>
                  </div>
                </div>
              ))}
            </div>
            <div className=" w-full flex flex-col gap-2 items-start">
              <p className="font-roboto font-normal text-xs lg:text-base  text-subheadingLite">
                Contact: contact@elythgroup.com
              </p>
              <p className="font-roboto font-normal text-xs lg:text-base  text-subheadingLite">
                © 2024 ELYTH Group. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
