import React, { useState, useEffect } from "react"; // Import useEffect
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { FaArrowRight } from "react-icons/fa6";
import { styles } from "../styles";

export default function TiltedCard() {
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation();

  const cardData = [
    {
      title: "Vision",
      description: "About our activity",
      bgColor: "bg-primary",
      route: "/vision", // Add route for dynamic navigation
    },
    {
      title: "Projects",
      description: "Our work in development",
      bgColor: "bg-primary",
      route: "/projects",
    },
    {
      title: "Team",
      description: "A trusted team at your service",
      bgColor: "bg-primary",
      route: "/team",
    },
    {
      title: "Solutions",
      description: "New menu available",
      bgColor: "bg-primary",
      route: "/solutions",
    },
  ];

  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    const currentRouteIndex = cardData.findIndex(
      (card) => card.route === location.pathname
    );
    setActiveCard(currentRouteIndex !== -1 ? currentRouteIndex : null);
  }, [location.pathname, cardData]);

  const handleCardClick = (index) => {
    setActiveCard(index);
    navigate(cardData[index].route); // Navigate to the specified route dynamically
  };

  if (
    location.pathname === "/auth/sign-up" ||
    location.pathname === "/auth/sign-in" ||
    location.pathname === "/auth/forgot-password" ||
    location.pathname === "/auth/verify-email" ||
    location.pathname === "/auth/verify-email" ||
    location.pathname === "/privacy-policy" ||
    location.pathname === "/eula" ||
    location.pathname === "/account-deletion"
  ) {
    return null; // Hide component on these routes
  }

  return (
    <div className="w-full h-full mt-8">
      <div
        className={`relative flex justify-center lg:py-8 items-center w-full h-[600px] md:h-[400px] lg:h-full`}
      >
        <img
          src={require("./../assets/Rectangle.png")}
          alt="Logo"
          className="w-full h-full lg:h-full ring-4 object-cover ring-primary"
        />

        <div
          className={`absolute w-full h-full gap-2 px-7 sm:px-10 md:px-16 lg:py-0 py-5 md:py-10 lg:px-24 lg:gap-8 xl:gap-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4`}
        >
          {cardData.map((card, index) => (
            <div
              key={index}
              onClick={() => handleCardClick(index)} // Click handler for cards
              className={`relative w-full h-full cursor-pointer text-white transition-transform duration-300 bg-opacity-60 shadow-lg transform -skew-x-6 lg:-skew-x-12 ${
                activeCard === index ? "bg-gold" : card.bgColor
              } ${
                activeCard === index
                  ? "lg:scale-105"
                  : "hover:bg-gold hover:bg-opacity-60"
              }`}
            >
              <div className="absolute z-20 flex flex-col justify-evenly w-full h-full p-4 lg:p-0 xl:px-6">
                <div className="pl-5 lg:pl-4 xl:pl-5">
                  <h2 className="text-2xl lg:text-3xl font-roboto font-bold">
                    {card.title}
                  </h2>
                  <p className="mt-2 font-poppins font-normal text-balance text-sm lg:text-base">
                    {card.description}
                  </p>
                </div>
                <div>
                  <button className="px-4 py-2 flex items-center gap-2 rounded-full">
                    <div className="lg:p-2 p-1 bg-white rounded-full">
                      <FaArrowRight color="#0E395D" />
                    </div>
                    <p className="font-roboto text-base lg:text-lg font-normal">
                      Learn More
                    </p>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
