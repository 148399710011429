import React, { useState } from "react";
import { Input } from "../../components/UI/Input";
import FormContainer from "../../components/FormContainer";
import Button from "../../components/UI/Button";
import FormLink from "../../components/FormLink";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Logo from "../../components/Logo/Logo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLogin, useResendOtp } from "../../service/auth";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/Auth";

export default function SignIn() {
  const {login} = useAuthContext()
  const navigate = useNavigate()
  const loginMutation = useLogin()
  const resendOTPMutation = useResendOtp()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const validateForm = () => {
    if (!email) {
      toast.error("Email is required!");
      return false;
    }

    // Check for valid email format using regex
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format!");
      return false;
    }

    if (!password) {
      toast.error("Password is required!");
      return false;
    }

    return true;
  };

  const handleSubmit =  async (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page

    if (validateForm()) {
      console.log("Form Submitted:");
      console.log("Email:", email);
      console.log("Password:", password);

      try {
        const res = await loginMutation.mutateAsync({
          "username": email,
          "password": password
        })

        if(res?.status){
          console.log('sdsa', res);
          toast.success(res?.message)
          setTimeout(() => {
            login(res)
          },700)
        }else{
          toast.error(res?.message)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message)
        if(error?.response?.data?.type?.[0] === "redirect_verify_user"){
          navigate('/auth/verify-email',{state: {
            email: email,
            type: 'userVerify'
          }}) 
        }
          console.log('error', error?.response?.data);
      }
      // Perform the actual login logic here (e.g., API call)
    }
  };

  return (
    <div className="flex w-full min-h-[95%] py-10 items-center flex-col flex-wrap md:flex-nowrap gap-4 bg-primary">
      <ToastContainer />
      <Logo />
      <FormContainer
        title="Welcome Back!"
        discription="Enter your login credential "
      >
        <form className="flex gap-4 pt-5 flex-col" onSubmit={handleSubmit}>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            className="bg-background bg-opacity-[17%] "
            placeholder="Enter email address"
          />

          <Input
            className="bg-background bg-opacity-[17%] "
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type={isVisible ? "text" : "password"}
            placeholder="Enter password"
            endContent={
              <button type="button" onClick={toggleVisibility}>
                {isVisible ? (
                  <FaEye color="#fff" />
                ) : (
                  <FaEyeSlash color="#fff" />
                )}
              </button>
            }
          />

          <div className="w-full justify-end items-center flex">
            <FormLink to="/auth/forgot-password">Forgot password?</FormLink>
          </div>
          <Button loading={loginMutation.isPending} type="submit">Log In</Button>
          <div className="w-full justify-center items-center flex">
            <p className="text-white font-poppins font-normal text-lg">
              Don't have an account?
              <FormLink to="/auth/sign-up"> Sign up</FormLink>
            </p>
          </div>
        </form>
      </FormContainer>
    </div>
  );
}
