import React, {useContext, useState} from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = React.createContext({
  login: arg => {},
  logout: arg => {},
  token: null,
  user: {}
});

export const AuthProvider = ({children}) => {
	const navigate = useNavigate()
  const [user, setUser] = useState(null);
  const [tkn, setTkn] = useState(null);
  const login = obj => {
    // const {token} = obj;
    const token = obj?.token || null
    // LogsHelper('JSON', obj)
    if (token) {
      setTkn(token)
      setUser(JSON.stringify(obj));
      localStorage.setItem('@auth', JSON.stringify(obj));
      navigate('/')
        // Navigation.reset({
        //   index: 0,
        //   routes: [{ name: 'Dashboard' }]
        // })
    }
  };

  const logout = () => {
    // LogsHelper('Logout....')
    setUser(null);
    setTkn(null)
    localStorage.removeItem('@auth');
    navigate('/')
      // Navigation.reset({
      //   index: 0,
      //   routes: [{ name: 'Login' }]
      // })
  };

  


  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        token: tkn
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);