import * as React from "react";
import { cn } from "../../utils/cn";
import { useState } from "react";

const Input = React.forwardRef(
  ({ className, type, onClick, endContent, maxLength, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    // Toggle password visibility
    const togglePasswordVisibility = () => {
      setIsPasswordVisible((prev) => !prev);
    };

    // Handle maxLength for number type
    const handleInputChange = (event) => {
      if (type === "number" || type == "text" && maxLength) {
        const inputValue = event.target.value;
        // If the value exceeds maxLength, truncate it
        if (inputValue.length > maxLength) {
          event.target.value = inputValue.slice(0, maxLength);
        }
      }
      // Call the original onChange if exists
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className="relative flex items-center">
        <input
          type={type === "password" && isPasswordVisible ? "text" : type}
          className={cn(
            "flex h-12 w-full border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-background focus-visible:outline-none focus-visible:ring-2 text-white focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          maxLength={props?.maxLength || 60}
          ref={ref}
          {...props}
          onChange={handleInputChange}
        />
        {/* End Content, such as the eye icon */}
        {endContent ? (
          <div
            className="absolute right-3 cursor-pointer"
            // onClick={togglePasswordVisibility}
          >
            {endContent}
          </div>
        ) : null}
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
