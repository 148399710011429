import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { projectPageData } from "../../data/Index";
import { styles } from "../../styles";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { useAuthContext } from "../../context/Auth";
import { useArea, useCountry, useProjects, useRegion } from "../../service/master";
import { useEffect } from "react";

export default function Projects() {
  const { token } = useAuthContext()
  const navigate = useNavigate();
  const [activeProject, setActiveProject] = useState(null); // Track active project
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedArea, setSelectedArea] = useState('')
  const [filters, setFilters] = useState({})

  //apis

  const { data: countryList } = useCountry()
  const { data: regionList } = useRegion()
  const { data: areaList } = useArea()
  const { data: projectList, isLoading } = useProjects(filters)


  useEffect(() => {
    if (selectedCountry) {
      setFilters(pv => ({
        ...pv,
        country: selectedCountry.includes("All") ? '' : selectedCountry
      }))
    }

    if (selectedRegion) {
      setFilters(pv => ({
        ...pv,
        region: selectedRegion.includes("All") ? '' : selectedRegion
      }))
    }

    if (selectedArea) {
      setFilters(pv => ({
        ...pv,
        area: selectedArea.includes("All") ? '' : selectedArea
      }))
    }

  }, [selectedRegion, selectedCountry, selectedArea])


  console.log('adsad', projectList);

  const handleProjectClick = (projectId) => {
    setActiveProject(projectId); // Set active project
    navigate(`/projects/${projectId}`,{state: {}}); // Navigate to the details page
  };




  return (
    <section id="Projects">
      <div className={`${styles.yPadding} ${styles.xPadding} w-full h-full `}>
        <h3 className="font-roboto font-bold text-heading text-4xl">
          Projects
        </h3>
        <p className="font-poppins font-normal text-subheading py-5">
          Below you will find the list of our real estate projects currently
          under development as well as others available for sale.
        </p>
  
        {
          !token ?
  
            <div className="bg-primary w-full px-5 md:px-0 py-3 flex flex-col lg:flex-row text-center items-center justify-center gap-3 lg:gap-5">
              <p className="font-poppins font-normal text-sm md:text-base text-white">
                Please log in to get more exclusive data on our current projects.
              </p>
              <button
                onClick={() => navigate("/auth/sign-in")}
                className="font-roboto bg-gold text-white font-medium py-2 px-10"
              >
                Log In
              </button>
            </div>
            :
            <>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-5">
                <select className="p-2 border rounded-md">
                  <option>All Continent</option>
                </select>
                <select
                  value={selectedCountry || "All Country"}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  className="p-2 border rounded-md">
                  <option value="All Country">All Country</option>
                  {
                    countryList?.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  }
                </select>
                <select
                  value={selectedRegion || "All Region"}
                  onChange={(e) => setSelectedRegion(e.target.value)}
                  className="p-2 border rounded-md">
                  <option>All Region</option>
                  {
                    regionList?.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  }
                </select>
                <select
                  value={selectedArea || "All Area"}
                  onChange={(e) => setSelectedArea(e.target.value)}
                  className="p-2 border rounded-md">
                  <option>All Area</option>
                  {
                    areaList?.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  }
                </select>
              </div>
  
              {/* Project List */}
              <div className="space-y-5">
                {
                  isLoading ? (
                    <div className="text-center my-5">
                      <p>Loading projects...</p>
                    </div>
                  ) : (
                    <>
                      {projectList?.results?.length === 0 ? (
                        <div className="text-center my-5 h-[250px]">
                          <div className="h-[100%] flex flex-col align-middle justify-center">
                            <p>No projects found.</p>
                          </div>
                        </div>
                      ) : (
                        projectList?.results?.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => handleProjectClick(item?.id)}
                            className={`border ${activeProject === item?.id
                              ? "bg-gold text-white"
                              : index % 2 === 0
                                ? "bg-border "
                                : "bg-[#E8EEF3]"} p-5 rounded-md cursor-pointer`}
                          >
                            <div className="w-full flex flex-col lg:flex-row lg:items-center gap-2">
                              <h4 className="font-roboto text-[#203543] font-semibold lg:text-xl">
                                Project {item.id} - {item.name}
                              </h4>
                              <p className="text-base flex items-center gap-2 text-headingLite">
                                {item?.region} <MdOutlineKeyboardDoubleArrowLeft />{" "}
                                {item?.area} <MdOutlineKeyboardDoubleArrowLeft />
                                {item?.country}
                              </p>
                            </div>
  
                            <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-y-4 xl:gap-2 mt-3">
                              <div>
                                <p className="font-semibold text-subheading">
                                  Investment Amount
                                </p>
                                <p className="pt-2">{item.investment_amount}</p>
                              </div>
                              <div>
                                <p className="font-semibold text-subheading">
                                  Project Amount
                                </p>
                                <p className="pt-2">{item.amount}</p>
                              </div>
                              <div>
                                <p className="font-semibold text-subheading">
                                  Percentage Return on Equity
                                </p>
                                <p className="pt-2">{item.percentage_return}</p>
                              </div>
                              <div>
                                <p className="font-semibold text-subheading">
                                  Project Duration
                                </p>
                                <p className="pt-2">{item.duration}</p>
                              </div>
                              <div>
                                <p className="font-semibold text-subheading">
                                  Style of Investment
                                </p>
                                <p className="pt-2">{item.style_of_investment}</p>
                              </div>
                              <div>
                                <p className="font-semibold text-subheading">
                                  Project Summary
                                </p>
                                {item.project_summary !== "Not available" ? (
                                  <button className="text-white mt-2 bg-gold px-5 py-1 font-medium">
                                    View All
                                  </button>
                                ) : (
                                  <p className="pt-2">{item.project_summary}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  )
                }
              </div>
            </>
        }
      </div>
    </section>
  );
  
}
