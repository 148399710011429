import { useMutation, useQuery } from '@tanstack/react-query';
import Endpoints from '../../api/index'
import useFetch from '../../hooks/index'

export const useRegistration = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.register,
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['register'],
    mutationFn: body => fetchPromise({body_: body, isTokenRequired: false}),
  });
};


export const useLogin = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.login,
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['login'],
    mutationFn: body => fetchPromise({body_: body, isTokenRequired: false}),
  });
};



export const useChangePassword = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.changePassword,
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['changePassword'],
    mutationFn: body => fetchPromise({body_: body}),
  });
};

export const useContact = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.contact,
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['contact'],
    mutationFn: body => fetchPromise({body_: body}),
  });
};

export const useDeleteAccount = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.deleteAccount,
		method: 'DELETE'
  });
  return useMutation({
    mutationKey: ['deleteAccount'],
    mutationFn: id => fetchPromise({id}),
  });
};


export const useAddInvestment = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.investment + '/',
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['investment'],
    mutationFn: body => fetchPromise({body_: body}),
  });
};

export const useForgotPassword = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.forgetPwd,
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['forgetPwd'],
    mutationFn: body => fetchPromise({body_: body, isTokenRequired: false}),
  });
};

export const useOtpVerify = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.otpVerify,
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['otpVerify'],
    mutationFn: body => fetchPromise({body_: body, isTokenRequired: false}),
  });
};

export const useVerifyUser = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.verifyUser,
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['verifyUser'],
    mutationFn: body => fetchPromise({body_: body, isTokenRequired: false}),
  });
};

export const useResendOtp = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.verifyUser,
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['resend'],
    mutationFn: body => fetchPromise({body_: body, isTokenRequired: false}),
  });
};

export const useResetPassword = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.resetPassword,
		method: 'POST'
  });
  return useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: body => fetchPromise({body_: body, isTokenRequired: false}),
  });
};