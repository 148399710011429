import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import HomeLayout from "./layouts/HomeLayout";
import {APIControlProvider} from './context/APIControlContext/index'
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const queryClient = new QueryClient()

  useEffect(() => {
    setIsLoggedIn(isLoggedIn);
  }, []);

  return (
    <>
     <QueryClientProvider client={queryClient}>
    <APIControlProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<HomeLayout />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
    </APIControlProvider>
     </QueryClientProvider>
     <ToastContainer />
    </>
  );
}

export default App;
