import React from "react";

import Hero from "./hero";

function Home({ count, increment, decrement }) {
  return (
    <>
      <Hero />
    </>
  );
}

export default Home;
