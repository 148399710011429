import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { projectPageData } from "../../data/Index"; // Import project data
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { styles } from "../../styles";
import { FaFileAlt } from "react-icons/fa";
import { useProjectsDetails } from "../../service/master";
import Endpoints from '../../api/index'

export default function ProjectDetail() {
  const {projectId} = useParams()
  const {data: projectDetails} = useProjectsDetails(projectId)
  // const { projectId } = useParams();

  // console.log('dsad', projectDetails);
  const projects = projectDetails?.results



  const project = projects?.find(
    (project) => project.id === projectId
  );


  if (!project) {
    return <p>Project not found!</p>;
  }

  return (
    <section id="ProjectDetail">
      <div className={`${styles.yPadding} ${styles.xPadding} w-full h-full`}>
        <div className="space-y-5">
          <div
            // Handle click to set active and navigate
            className={`border  p-5  rounded-md cursor-pointer`}
          >
            <div className="w-full flex flex-col lg:flex-row lg:projects-center gap-2">
              <h4 className="font-roboto text-[#203543]  font-semibold lgtext-xl">
                Project - {project.name}
              </h4>
              <p className="text-base flex items-center projects-center gap-2 text-headingLite">
                {project.region} <MdOutlineKeyboardDoubleArrowLeft />{" "}
                {project.area} <MdOutlineKeyboardDoubleArrowLeft />
                {project.country}
              </p>
            </div>

            <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-4 xl:gap-2 mt-3">
              <div>
                <p className="font-semibold text-subheading  ">
                  Investment Amount
                </p>
                <p className="pt-2">{project.investment_amount}</p>
              </div>
              <div>
                <p className="font-semibold text-subheading ">Project Amount</p>
                <p className="pt-2">{project.amount}</p>
              </div>
              <div>
                <p className="font-semibold text-subheading ">
                  Percentage Return on Equity
                </p>
                <p className="pt-2">{project.percentage_return}</p>
              </div>
              <div>
                <p className="font-semibold text-subheading ">
                  Project Duration
                </p>
                <p className="pt-2">{project.duration}</p>
              </div>
              <div>
                <p className="font-semibold text-subheading ">
                  Style of Investment
                </p>
                <p className="pt-2">{project.style_of_investment}</p>
              </div>
            </div>
            <div className="pt-5">
              <p className="font-semibold pb-5 text-subheading ">
                Project Summary
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full h-full gap-5">
                {project?.files_array?.length > 0 ? (
                  project?.files_array?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex  flex-col  w-full h-full  p-3 border-2 border-border"
                      >
                        <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGAA4Wd4bco5Xv33GasXrnDdQT5OFXwa3HUQ&s'} alt={item.title} />
                        <div className="space-y-1 pt-3">
                          <p className="font-semibold font-roboto text-heading text-lg">
                            {item.file?.split('/')?.pop()}
                          </p>
                          <div className="  flex items-center flex-col w-full">
                            {/* <p className="line-clamp-2">{item.description}</p> */}
                          </div>
                        </div>
                        <a 
                          href={Endpoints.url + item.file}  // URL of the PDF file
                          download={`${item.file?.split('/')?.pop()}`}  
                        className="text-white mt-2 flex items-center gap-2 w-fit bg-gold px-14 py-2 font-medium">
                          <FaFileAlt /> Download
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center text-lg font-semibold text-red-500">
                    No project summary available.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
