import React from "react";
import { useNavigate } from "react-router-dom";

export default function Logo() {
  const navigate = useNavigate();
  return (
    <div>
      <img
        onClick={() => navigate("/")}
        src={require("./../../assets/elythWhte.png")}
        alt="Logo"
        className="  h-14  md:h-14 lg:h-[80px]"
      />
    </div>
  );
}
