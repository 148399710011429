import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}


export function logErrorMessages(errorResponse) {
  if (errorResponse?.data) {
      Object.entries(errorResponse.data).forEach(([field, messages]) => {
          if (Array.isArray(messages) && messages.length > 0) {
              console.log(`Error in ${field}: ${messages[0]}`);
          }
      });
  } else {
      console.log('No error message available.');
  }
}