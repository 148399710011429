import {
  Rectangle146,
  Rectangle147,
  Rectangle148,
  Rectangle149,
} from "../assets";
import { MaskGroup1, MaskGroup2, MaskGroup3 } from "../assets/svg";

export const projectData = [
  { icon: MaskGroup1, count: 1, title: "Completed Projects" },
  { icon: MaskGroup2, count: 2, title: "Current Projects" },
  { icon: MaskGroup3, count: 3.7, title: "Average Return" },
];

export const projectPageData = [
  {
    project_id: "102",
    title: "Renovation of a building with 2 apartments",
    location: {
      continent: "Europe",
      country: "Switzerland",
      region: "Jura",
      area: "Ajoie",
    },
    investment_amount: "1.2M",
    project_amount: "7.4M",
    percentage_return_on_equity: "3.70%",
    project_duration: "3 ans",
    style_of_investment: "Homeinvest",
    project_summary: [
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle146,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle147,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle148,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle149,
      },
    ],
  },
  {
    project_id: "103",
    title: "Renovation of a building with 2 apartments",
    location: {
      continent: "Europe",
      country: "Switzerland",
      region: "Jura",
      area: "Ajoie",
    },
    investment_amount: "1.2M",
    project_amount: "7.4M",
    percentage_return_on_equity: "3.70%",
    project_duration: "3 ans",
    style_of_investment: "Homeinvest",
    project_summary: [
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle146,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle147,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle148,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle149,
      },
    ],
  },
  {
    project_id: "104",
    title: "Renovation of a building with 2 apartments",
    location: {
      continent: "Europe",
      country: "Switzerland",
      region: "Jura",
      area: "Ajoie",
    },
    investment_amount: "1.2M",
    project_amount: "7.4M",
    percentage_return_on_equity: "3.70%",
    project_duration: "3 ans",
    style_of_investment: "Homeinvest",
    project_summary: [
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle146,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle147,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle148,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle149,
      },
    ],
  },
  {
    project_id: "105",
    title: "Renovation of a building with 2 apartments",
    location: {
      continent: "Europe",
      country: "Switzerland",
      region: "Jura",
      area: "Ajoie",
    },
    investment_amount: "1.2M",
    project_amount: "7.4M",
    percentage_return_on_equity: "3.70%",
    project_duration: "3 ans",
    style_of_investment: "Homeinvest",
    project_summary: "Not available",
  },
  {
    project_id: "106",
    title: "Renovation of a building with 2 apartments",
    location: {
      continent: "Europe",
      country: "Switzerland",
      region: "Jura",
      area: "Ajoie",
    },
    investment_amount: "1.2M",
    project_amount: "7.4M",
    percentage_return_on_equity: "3.70%",
    project_duration: "3 ans",
    style_of_investment: "Homeinvest",
    project_summary: [
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle146,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle147,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle148,
      },
      {
        title: "PDF Title Here",
        description:
          "Lorem ipsumit amet, consiscing elit, sed do eiusmod tempor.",
        image: Rectangle149,
      },
    ],
  },
  {
    project_id: "107",
    title: "Renovation of a building with 2 apartments",
    location: {
      continent: "Europe",
      country: "Switzerland",
      region: "Jura",
      area: "Ajoie",
    },
    investment_amount: "1.2M",
    project_amount: "7.4M",
    percentage_return_on_equity: "3.70%",
    project_duration: "3 ans",
    style_of_investment: "Homeinvest",
    project_summary: "Not available",
  },
];
