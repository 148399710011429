import React, { useState, useRef, useEffect } from "react";
import { styles } from "../styles";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { RiUser3Line } from "react-icons/ri";

import {
  FaUser,
  FaCalculator,
  FaLock,
  FaSignOutAlt,
  FaFileAlt
} from "react-icons/fa";
import { FaUserXmark } from "react-icons/fa6";
import { useAuthContext } from "../context/Auth";
import { useDeleteAccount } from "../service/auth";
import { toast } from "react-toastify";
import { useProfileData } from "../service/master";
export default function Header() {
  const { token, user, logout } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const {mutateAsync} = useDeleteAccount()

  const userData = JSON.parse(user);
  // console.log('dsad', userData);

  const location = useLocation();
  const dropdownRef = useRef(null);

  const { data: profileData } = useProfileData(userData?.user?.id);

  console.log('sad', profileData);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenDropDown(false); // Close the dropdown if clicked outside
      }
    };

    // Add event listener to capture all clicks
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (
    location.pathname === "/auth/sign-up" ||
    location.pathname === "/auth/sign-in" ||
    location.pathname === "/auth/forgot-password" ||
    location.pathname === "/auth/verify-email" ||
    location.pathname === "/auth/set-new-password"
  ) {
    return null; // Hide component on these routes
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  const hanleDeleteAccount = () => {
    const isConfirmed = window.confirm("Are you sure you want to proceed?");
    if (isConfirmed) {
      // If the user clicks "OK"
      // alert("You have confirmed!");
      mutateAsync(userData?.user?.id).then(() => {
          toast.success('Account deleted')
          logout()
      }).catch((err) => {
        toast.error('Someting went wrong.')
      })
    } 
  }

  return (
    <>
      <header className={`${styles.xPadding} bg-background pb-3`}>
        <div className="flex justify-between items-start mx-auto    pt-5">
          <img
            onClick={() => navigate("/")}
            src={require("./../assets/elyth.png")}
            alt="Logo"
            className="  h-10  md:h-14 lg:h-[80px]"
          />

          {/* Hamburger Icon */}
          <div className="md:hidden">
            <button
              className="text-primary focus:outline-none"
              onClick={toggleMenu}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-4">
            {!token ? (
              <>
                <button
                  onClick={() => navigate("/auth/sign-in")}
                  className="bg-primary font-roboto text-white font-medium py-2 px-10"
                >
                  Log In
                </button>
                <button
                  onClick={() => navigate("/auth/sign-up")}
                  className="bg-gold text-white font-roboto font-medium py-2 px-10 ml-2 mr-2"
                >
                  Sign Up
                </button>
              </>
            ) : (
              <>
                <div ref={dropdownRef} className="w-full flex items-center ">
                  <div className="relative inline-block text-left">
                    {/* Toggle Button */}
                    <button
                      onClick={() => setIsOpenDropDown(!isOpen)}
                      className=" text-heading font-bold py-2 px-4 rounded inline-flex items-center"
                    >
                      <RiUser3Line className="mr-2" />
                      Hello, {profileData?.first_name}
                      <svg
                        className="w-4 h-4 ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>

                    {/* Dropdown Menu */}
                    {isOpenDropDown && (
                      <div className="absolute right-0 mt-2 w-56 origin-top-right  border border-gray-200 rounded-md shadow-lg bg-white">
                        <div className="py-1">
                          <Link
                            to="/my-account"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            <FaUser className="inline mr-2" /> My Account
                          </Link>
                          <Link
                            to="/investment"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            <FaFileAlt className="inline mr-2" /> Investment
                            Form
                          </Link>
                          <Link
                            to="/calculator"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            <FaCalculator className="inline mr-2" /> Calculator
                          </Link>
                          <Link
                            to="/change-password"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            <FaLock className="inline mr-2" /> Change Password
                          </Link>
                          <a
                            onClick={() => {
                              logout();
                            }}
                            // href="#logout"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            <FaSignOutAlt className="inline mr-2" /> Log Out
                          </a>
                          <a
                            onClick={() => {
                              // logout();
                              hanleDeleteAccount()
                            }}
                            // href="#logout"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            <FaUserXmark className="inline mr-2" /> Delete Account
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            <button
              onClick={() => navigate("/contact")}
              className="bg-transparent font-medium font-roboto text-primary py-2 px-10 border border-primary"
            >
              Contact
            </button>
          </div>
        </div>

        {/* Mobile Menu with Animation */}
        <div
          className={`${
            isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
          } overflow-hidden transition-all duration-300 z-[999] ease-in-out md:hidden`}
        >
          <div className="flex justify-center items-center flex-col w-full gap-y-4 pt-10">
            {!token ? (
              <>
                <button
                  onClick={() => {
                    navigate("/auth/sign-in");
                    setIsOpen(false); // Close the menu
                  }}
                  className="bg-primary outline-none w-40 font-roboto text-white font-bold py-2 px-10"
                >
                  Log In
                </button>
                <button
                  onClick={() => {
                    navigate("/auth/sign-up");
                    setIsOpen(false); // Close the menu
                  }}
                  className="bg-gold outline-none  w-40 text-white font-poppins font-bold py-2 px-10"
                >
                  Sign Up
                </button>
                <button
                  onClick={() => {
                    navigate("/contact");
                    setIsOpen(false); // Close the menu
                  }}
                  className=" bg-transparent outline-none  w-40 font-medium text-primary py-2 px-10 border border-primary"
                >
                  Contact
                </button>
              </>
            ) : (
              <div className="mt-2 w-full rounded-md bg-white">
                <button className="text-heading font-bold py-2 px-4 rounded inline-flex items-center">
                  <RiUser3Line className="mr-2" />
                  Hello, {userData?.user?.first_name}
                </button>
                <div className="py-1">
                  <Link
                    to="/my-account"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => setIsOpen(false)} // Close the menu
                  >
                    <FaUser className="inline mr-2" /> My Account
                  </Link>
                  <Link
                    to="/investment"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => setIsOpen(false)} // Close the menu
                  >
                    <FaFileAlt className="inline mr-2" /> Investment Form
                  </Link>
                  <Link
                    to="/calculator"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => setIsOpen(false)} // Close the menu
                  >
                    <FaCalculator className="inline mr-2" /> Calculator
                  </Link>
                  <Link
                    to="/change-password"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => setIsOpen(false)} // Close the menu
                  >
                    <FaLock className="inline mr-2" /> Change Password
                  </Link>
                  <a
                    onClick={() => {
                      logout();
                      setIsOpen(false); // Close the menu
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    <FaSignOutAlt className="inline mr-2" /> Log Out
                  </a>
                  <a
                    onClick={() => {
                      setIsOpen(false); // Close the menu
                      hanleDeleteAccount()
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    <FaUserXmark className="inline mr-2" /> Delete Account
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
}
