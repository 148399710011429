import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Endpoints from '../../api/index'
import useFetch from '../../hooks/index'



export const useCountry = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.countryList,
		method: 'GET'
  });
  return useQuery({
    queryKey: ['country'],
    queryFn: body => fetchPromise({}),
  });
};

export const useRegion = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.regionList,
		method: 'GET'
  });
  return useQuery({
    queryKey: ['region'],
    queryFn: body => fetchPromise({}),
  });
};


export const useArea = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.areaList,
		method: 'GET'
  });
  return useQuery({
    queryKey: ['area'],
    queryFn: body => fetchPromise({}),
  });
};

export const useProfileData = (id) => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: `${Endpoints.profileData}/${id}`,
		method: 'GET'
  });
  return useQuery({
    queryKey: ['profile'],
    queryFn: body => fetchPromise({}),
  });
};

export const useUpdateProfile = (id) => {
	const queryClinet = useQueryClient()
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: `${Endpoints.profileData}/${id}/`,
		method: 'PATCH'
  });
  return useMutation({
    mutationKey: ['updateprofile', id],
    mutationFn: body => fetchPromise({body_: body}),
		onSuccess: () => {
			queryClinet.refetchQueries({queryKey: 'profile'})
		}
  });
};



export const useInvestment = () => {
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.investment,
		method: 'GET'
  });
  return useQuery({
    queryKey: ['investment'],
    queryFn: body => fetchPromise({}),
  });
};



export const useProjects = (filters) => {
	console.log('sad', filters);
	
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: Endpoints.projectList,
		method: 'GET',
		params: filters
  });
  return useQuery({
    queryKey: ['project', filters],
    queryFn: body => fetchPromise({}),
  });
};


export const useProjectsDetails = (id) => {
	
  let {fetchPromise} = useFetch({
    showErrorMessage: true,
    endpoint: `${Endpoints.singleProject}`,
		method: 'GET',
  });
  return useQuery({
    queryKey: ['project', id],
    queryFn: body => fetchPromise({}),
  });
};
