export default {
	baseUrl: 'https://backend.elythgroup.com/api/v1',
	url: 'https://backend.elythgroup.com',
	register: '/user/register',
	login: '/user/login',
	login: '/user/login',
	changePassword: '/user/change-password',
	contact: '/contact/',
	deleteAccount: '/user/delete-account/',
	forgetPwd: '/user/forgot-password',
	otpVerify: '/user/forgot-password-verify',
	verifyUser: '/user/register-verify',
	resendOtp: '/user/resend-otp',
	resetPassword: '/user/set-new-password',
	countryList: '/country/country',
	regionList: '/country/region',
	areaList: '/country/area',
	profileData: '/user',
	projectList: '/project',
	singleProject: '/project',
	investment: '/investment',
 }
