import React, { useState } from "react";
import { Input } from "../../components/UI/Input";
import FormContainer from "../../components/FormContainer";
import Button from "../../components/UI/Button";
import FormLink from "../../components/FormLink";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Logo from "../../components/Logo/Logo";
import { IoLocationOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRegistration } from "../../service/auth";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function SignUp() {
  const navigate = useNavigate()
  const registerMutation = useRegistration()
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false)
  const [isVisible2, setIsVisible2] = useState(false);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
    password: "",
    confirmPassword: "",
    mobile: ""
  });
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
    password: "",
    confirmPassword: "",
    mobile: ""
  });
  

  const toggleVisibility = () => setIsVisible(!isVisible);
  const toggleVisibility2 = () => setIsVisible2(!isVisible2);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    const {
      firstName,
      lastName,
      email,
      address,
      city,
      zipCode,
      password,
      confirmPassword,
      mobile
    } = formValues;
  
    if (!firstName) errors.firstName = "First name is required";
    if (!lastName) errors.lastName = "Last name is required";
    if (!email) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(email)) errors.email = "Invalid email format";
  
    // if (!mobile) errors.mobile = "Mobile number is required";
    if (!address) errors.address = "Address is required";
    if (!city) errors.city = "City is required";
    if (!zipCode) errors.zipCode = "Zip code is required";
  
    if (!password) errors.password = "Password is required";
    if (!confirmPassword) errors.confirmPassword = "Please confirm your password";
    else if (password !== confirmPassword) errors.confirmPassword = "Passwords do not match";
  
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true)

    console.log();

    if (validateForm()) {
      // toast.success("Sign up successful!");
      console.log("Form Values: ", formValues);

      try {
        // console.log('sad', code);
        // console.log('sad', phone);

        const res = await registerMutation.mutateAsync({
          "username": formValues.email,
          "password": formValues.password,
          "first_name": formValues.firstName,
          "last_name": formValues.lastName,
          "phone": phone, 
          "address": formValues.address,
          "city": formValues.city,
          "zipcode": formValues.zipCode,
          "confirm_password": formValues.confirmPassword,
          "country_code": `+${code}`
      })
      
        if(res?.status){
          toast.success(res?.message)
          setTimeout(() => {
            navigate('/auth/verify-email',{state: {
              email: formValues.email,
              type: 'userVerify'
            }})
          },1500)
        }else {
          toast.error(res?.message)
        }
      } catch (error) {
        // console.log(('sad', error.response?.data?.phone[0]));
        toast.error(error.response?.data?.phone[0])
      }
    
      // console.log('sad', res);
      // Handle the actual form submission logic (e.g., sending the data to the backend).
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  return (
    <div className="flex w-full min-h-[95%] py-10 items-center flex-col flex-wrap md:flex-nowrap gap-4 bg-primary">
      <ToastContainer />
      <Logo />
      <FormContainer title="Let’s Get Started!" discription="Enter your signup details ">
        <form className="flex gap-4 pt-5 flex-col" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-3 w-full">
            <div>
              <Input
                type="text"
                name="firstName"
                className="bg-background bg-opacity-[17%] "
                placeholder="Enter first name"
                value={formValues.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={20}
              />
              {formErrors.firstName && <p className="text-red-500 text-sm py-2">{formErrors.firstName}</p>}
            </div>
            <div>
              <Input
                type="text"
                name="lastName"
                className="bg-background bg-opacity-[17%] "
                placeholder="Enter last name"
                value={formValues.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={20}
              />
              {formErrors.lastName && <p className="text-red-500 text-sm py-2">{formErrors.lastName}</p>}
            </div>
          </div>
  
          <div>
            <Input
              type="email"
              name="email"
              className="bg-background bg-opacity-[17%] "
              placeholder="Enter email address"
              value={formValues.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {formErrors.email && <p className="text-red-500 text-sm py-2">{formErrors.email}</p>}
          </div>
  
          <div>
            {/* <Input
              type="text"
              name="mobile"
              className="bg-background bg-opacity-[17%] "
              placeholder="Enter mobile number"
              value={formValues.mobile}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={13}
            /> */}
             <PhoneInput
              country={"eg"}
              // enableSearch={true}
              inputClass="bg-background bg-opacity-[17%] w-full text-white py-3"
              value={formValues.mobile}
              onChange={(value, country, formattedValue) => {
                // handleChange(value)
                const numberStr = value.toString();
                const codeStr = country?.dialCode?.toString();
                const firstDigits = numberStr.substring(0, codeStr.length); // Get the first `codeStr.length` digits
                const remainingNumber = numberStr.substring(codeStr.length); 
                
                if (firstDigits === codeStr) {
                    // console.log(remainingNumber);
                       setCode(country?.dialCode)
                       setPhone(remainingNumber)
                      // setFormValues({ ...formValues, mobile: remainingNumber });
                 }
              }
              }
            />
            { isSubmit && phone == "" && <p className="text-red-500 text-sm py-2">Mobile number is required</p>}
          </div>
  
          <div>
            <Input
              type="text"
              name="address"
              className="bg-background bg-opacity-[17%] "
              placeholder="Enter address"
              value={formValues.address}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={50}
              endContent={<IoLocationOutline color="#fff" />}
            />
            {formErrors.address && <p className="text-red-500 text-sm py-2">{formErrors.address}</p>}
          </div>
  
          <div className="grid grid-cols-2 gap-3 w-full">
            <div>
              <Input
                type="text"
                name="city"
                className="bg-background bg-opacity-[17%] "
                placeholder="Enter city"
                value={formValues.city}
                onChange={handleChange}
                maxLength={20}
                onBlur={handleBlur}
              />
              {formErrors.city && <p className="text-red-500 text-sm py-2">{formErrors.city}</p>}
            </div>
            <div>
              <Input
                type="text"
                name="zipCode"
                className="bg-background bg-opacity-[17%] "
                placeholder="Enter zip code"
                value={formValues.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={6}
              />
              {formErrors.zipCode && <p className="text-red-500 text-sm py-2">{formErrors.zipCode}</p>}
            </div>
          </div>
  
          <div>
            <Input
              className="bg-background bg-opacity-[17%] "
              type={isVisible ? "text" : "password"}
              name="password"
              placeholder="Enter password"
              value={formValues.password}
              onChange={handleChange}
              maxLength={30}
              onBlur={handleBlur}
              endContent={
                <button type="button" onClick={toggleVisibility}>
                  {isVisible ? <FaEye color="#fff" /> : <FaEyeSlash color="#fff" />}
                </button>
              }
            />
            {formErrors.password && <p className="text-red-500 text-sm py-2">{formErrors.password}</p>}
          </div>
  
          <div>
            <Input
              className="bg-background bg-opacity-[17%] "
              type={isVisible2 ? "text" : "password"}
              name="confirmPassword"
              placeholder="Confirm password"
              value={formValues.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={30}
              endContent={
                <button type="button" onClick={toggleVisibility2}>
                  {isVisible2 ? <FaEye color="#fff" /> : <FaEyeSlash color="#fff" />}
                </button>
              }
            />
            {formErrors.confirmPassword && <p className="text-red-500 text-sm py-2">{formErrors.confirmPassword}</p>}
          </div>
  
          <Button loading={registerMutation.isPending} className="mt-5" type="submit">
            Sign Up
          </Button>
        </form>
      </FormContainer>
    </div>
  );
}
