import Home from "./pages/homepage/home";

import About from "./pages/homepage/about";
import Vision from "./pages/homepage/vision";
import SignUp from "./pages/auth/SignUp";
import SignIn from "./pages/auth/SignIn";
import Projects from "./pages/homepage/projects";
import ForgotPass from "./pages/auth/ForgotPass";
import VerifyEmail from "./pages/auth/VerifyEmail";
import SetNewPassword from "./pages/auth/SetNewPassword";
import Solutions from "./pages/homepage/solutions";
import Team from "./pages/homepage/team";
import ProjectsDetails from "./pages/homepage/projectsDetails";
import Contact from "./pages/homepage/contact";
import MyAccount from "./pages/homepage/MyAccount";
import ChangePassword from "./pages/homepage/ChangePassword";
import Investment from "./pages/homepage/Investment";
import Calculator from "./pages/homepage/Calculator";
import PrivacyPolicy from "./pages/homepage/privacy-policy";
import AccountDeletion from "./pages/homepage/account-deletion";
import EULA from "./pages/homepage/eula";

export const routes = [
  {
    layout: "HomeLayout", // Use HomeLayout for homepage-related pages
    pages: [
      {
        path: "/",
        element: <Home />,
      },
      {
        name: "about",
        path: "/about",
        element: <About />,
      },
      {
        name: "vision",
        path: "/vision",
        element: <Vision />,
      },
      {
        name: "projects",
        path: "/projects",
        element: <Projects />,
      },
      {
        name: "ProjectDetail",
        path: "/projects/:projectId",
        element: <ProjectsDetails />,
      },
      {
        name: "team",
        path: "/team",
        element: <Team />,
      },
      {
        name: "solutions",
        path: "/solutions",
        element: <Solutions />,
      },
      {
        name: "contact",
        path: "/contact",
        element: <Contact />,
      },
      {
        name: "Privacy Policy",
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        name: "Account Deletion",
        path: "/account-deletion",
        element: <AccountDeletion />,
      },
      {
        name: "eula",
        path: "/eula",
        element: <EULA />,
      },
      {
        name: "My Account",
        path: "/my-account",
        element: <MyAccount />,
      },
      {
        name: "ChangePassword",
        path: "/change-password",
        element: <ChangePassword />,
      },
      {
        name: "Investment",
        path: "/investment",
        element: <Investment />,
      },
      {
        name: "Calculator",
        path: "/calculator",
        element: <Calculator />,
      },
      {
        name: "signIn",
        path: "/auth/sign-in",
        element: <SignIn />,
      },
      {
        name: "signUp",
        path: "/auth/sign-up",
        element: <SignUp />,
      },
      {
        name: "ForgotPassword",
        path: "/auth/forgot-password",
        element: <ForgotPass />,
      },
      {
        name: "VerifyEmail",
        path: "/auth/verify-email",
        element: <VerifyEmail />,
      },
      {
        name: "SetNewPassword",
        path: "/auth/set-new-password",
        element: <SetNewPassword />,
      },
      {
        name: "notFound",
        path: "*",
        element: <p>not found</p>,
      },
    ],
  },
];

export default routes;
